// React
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useVolume } from '../../VolumeContext';
import { UserContext } from '../../Context';

// Ionic
import { Storage } from '@ionic/storage';
import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { IonRange } from '@ionic/react';
import { volumeHigh, volumeOff } from 'ionicons/icons';

// Music
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import H5AudioPlayer from 'react-h5-audio-player';
import musicTracks from '../../data/music.json';

// Pages
import { maximus } from '../../pages/Admin';
import ReleaseNotes from '../../pages/ReleaseNotes';

// Components
import Version from './Version';

// Styles
import './Settings.scss';

// Images 
import brLogo from './../../images/gladiator/brokenrealityinc-logo.png'
import colyseusLogo from './../../images/gladiator/colyseus-logo.png'

// Interfaces
import { UserState } from '../../interfaces/User.interface';

// Utils
import { debounce } from 'lodash';

// Prepare local storage
const storage = new Storage();
storage.create();

export interface Playlist {
    name: string;
    src: string;
}

interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const Settings: React.FC<Props> = ({ isOpen, setIsOpen }) => {

    const { router, user, tokensLoaded, setAudioPlaying, musicPlayer, setMusicPlayer } = useContext<UserState>(UserContext);
    const { volume_Music, setVolume_Music, volume_SFX, setVolume_SFX } = useVolume();

    const [showToast, setShowToast] = useState(false);
    const [releaseNotes, setReleaseNotes] = useState(false);
    const [currentTrack, setTrackIndex] = useState<number | null>(null);

    const playerRef = useRef<AudioPlayer | null>(null);
    const playlist: Playlist[] = musicTracks;

    // Save settings to local storage with debounce
    const saveSettings = debounce(() => {
        storage.set('volume_Music', volume_Music);
        storage.set('volume_SFX', volume_SFX);
    }, 250);

    /**
     * Initialize music player and set a random track on first load
     */
    useEffect(() => {
        if (currentTrack === null) {
            setTrackIndex(Math.floor(Math.random() * playlist.length));
        }

        if (!musicPlayer && playerRef.current) {
            setTimeout(() => {
                if (playerRef.current) {
                    setMusicPlayer(playerRef.current);
                }
            }, 1000);
        }
    }, [currentTrack, musicPlayer, setMusicPlayer, playlist.length]);

    /**
     * Sync volume with the player
     */
    useEffect(() => {
        if (playerRef.current?.audio.current) {
            playerRef.current.audio.current.volume = volume_Music;
        }
    }, [volume_Music]);

    /**
     * Handle next/previous track
     */
    const handleNextTrack = (direction: 'next' | 'prev') => {
        if (currentTrack !== null) {
            const nextIndex =
                direction === 'next'
                    ? (currentTrack + 1) % playlist.length
                    : (currentTrack - 1 + playlist.length) % playlist.length;

            if (playlist[nextIndex]) {
                setTrackIndex(nextIndex);
                setTimeout(() => musicPlayer?.audio?.current?.play(), 500);
            }
        }
    };

    /**
     * Handle end of track
     */
    const handleEnd = () => handleNextTrack('next');

    /**
     * Close settings when clicking outside
     */
    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            const panelSettings = document.getElementById('panel-settings');
            const panelSettingsButton = document.getElementById('settings-button');

            if (panelSettingsButton?.contains(e.target as Node)) return;
            if (isOpen && panelSettings && !panelSettings.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [isOpen, setIsOpen]);

    return (
        <>
        <div id='panel-settings' className={`panel-gladiator top-left ${isOpen ? `open` : ``}`}>
            <IonContent className="ion-padding">
                <h3 className='gladiator-arena mb0 mt2'>Music</h3>
                <div className="music-player row mb3 ba b--white-20 mt1 pb3">
                    <div className="columns small-12">
                        <div className="music-player">
                            <p className='mb3'>
                                <span className='moth-yellow'>{currentTrack !== null && playlist[currentTrack]?.name}</span>
                            </p>
                            {currentTrack !== null && user.wallet && tokensLoaded && (
                                <AudioPlayer
                                    ref={playerRef}
                                    loop={false}
                                    volume={volume_Music}
                                    src={playlist[currentTrack]?.src}
                                    onEnded={handleEnd}
                                    onClickNext={() => handleNextTrack('next')}
                                    onClickPrevious={() => handleNextTrack('prev')}
                                    showJumpControls={false}
                                    showSkipControls={true}
                                    showFilledVolume={true}
                                    showDownloadProgress={true}
                                    autoPlayAfterSrcChange={false}
                                    onVolumeChange={(e: any) => {
                                        setVolume_Music(e?.target?.volume)
                                        saveSettings()
                                    }}
                                    onPlaying={() => {
                                        setAudioPlaying(true)
                                    }}
                                    onPause={() => {
                                        setAudioPlaying(false)
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="sound-effects-vol row pt2 ba b--white-20 mt3 pb2">
                    <div className="columns small-12 medium-4 flex justify-start items-center">
                        <label htmlFor="volume_Music">Battle Sound FX</label>
                    </div>
                    <div className="columns small-12 medium-8">
                            <IonRange
                                id='volume_SFX'
                                min={0}
                                max={1}
                                step={0.01}
                                value={volume_SFX}
                                onIonChange={(e) => {
                                    setVolume_SFX(e.detail.value as number)
                                    saveSettings()
                                }}
                                pin={false}
                                snaps={true}
                                dualKnobs={false}
                                ticks={false}
                                color='dark'
                                debounce={100}
                                disabled={false}
                                mode='md'
                                className='ion-no-padding'
                                style={{ '--bar-background-active': 'var(--ion-color-primary)' }}
                                >
                                <div slot="start">
                                    <IonIcon icon={volumeOff} size='large' />
                                </div>
                                <div slot="end">
                                    <IonIcon icon={volumeHigh} size='large' />
                                </div>
                            </IonRange>
                    </div>                                
                </div>
                <div className="release-notes row ba b--white-20 mt3 flex items-center">
                    <div className="columns small-12 flex justify-between items-center pt2 pb2">
                        <h3 className='f5 ma0 pa0'>Release Notes</h3>
                        <IonButton slot='end' color={'transparent'} onClick={() => {
                            setReleaseNotes(true)
                            setIsOpen(false)
                        }} className='mr3'><Version /></IonButton>
                    </div>
                </div>
                {user.wallet === maximus ? (
                    <>
                        <div className="admin-settings row ba b--white-20 mt3 flex items-center">
                            <div className="column small-12">
                                <IonButton expand='block' color={'transparent'} onClick={() => {
                                    router?.push('/maximus')
                                    setIsOpen(false)
                                }} className='mr3'>
                                    <p className='gladiator-arena mb0 mt2'>Admin</p>
                                </IonButton>
                            </div>
                        </div>
                    </>
                ) : null}
            </IonContent>
        </div>

        <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message="Settings saved successfully"
            duration={3000}
            position="bottom"
            color={'primary'}
        />

        <IonModal isOpen={releaseNotes} backdropDismiss={false} className='release-notes-modal'>
            <IonHeader>
                <IonToolbar>
                    <div className="row ma0 pa0">
                        <div className="columns ma0 pa0 small-3 tl flex items-center">
                            <img src={brLogo} width={28} height={28} alt="" className='ml3' />
                        </div>
                        <div className="columns ma0 pa0 small-6 tl flex items-center justify-center">
                            <IonTitle class='mt2 ml3 tc'><span className="gladiator-arena">Gladiator</span></IonTitle>
                        </div>
                        <div className="columns ma0 pa0 small-3 tr flex justify-end items-center">
                            <IonButton slot='end' color={'transparent'} onClick={() => setReleaseNotes(false)} className='mr3'>Close</IonButton>
                        </div>
                    </div>                  
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ReleaseNotes />
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <div className="row map pa0">
                        <div className="columns small-6 tl flex items-center">
                            <p className="ma0 pa0 lh-copy f6">
                                &copy; {new Date().getFullYear()} <a href="https://brokenreality.com" target="_blank" rel="noopener noreferrer" className="link white underline-hover pointer">Brokenreality Inc</a>.
                            </p>
                        </div>
                        <div className="columns small-6 tr flex justify-end items-center">
                            <p className='mr3 nowrap f6'>Powered by</p>
                            <a href="https://colyseus.io" target="_blank" rel="noopener noreferrer" className="link white">
                                <img src={colyseusLogo} width={120} alt="" />
                            </a>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonModal>

        </>
    );
};

export default Settings;
