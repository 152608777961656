import { objectPath_bufficorns } from "..";
import { applyEffects, calculateAngleAndDistance, calculateTranslation } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";
import { gsap } from "gsap";

/**
 * Buff Bash: Card Moves Across Screen
 * @param c1 Card Sending Action
 * @param c2 Target Card
 */
export function buffBash(c1: TokenMetadata, c2: TokenMetadata) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);
    
        if (source && target) {
            const {
                translateX,
                translateY
            } = calculateTranslation(source, target);
    
            source.style.transform = `translate(${translateX}px, ${translateY}px)`;
            applyEffects('buff-bash', 'cardAbility', target, source).then(() => {
                source.style.transform = '';
                resolve(true)
            })
    
        }

    })

}

/**
 * Spork Lift
 * @param c1 Card Sending Action
 * @param c2 Cards Receiving Damage
 * @returns Boolean
 */
export function sporkLift(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values
            applyEffects('spork-lift', mode, targets as HTMLElement[], undefined, 750, 750).then(() => {
                resolve(true)
            })

            // console.log('targets with attribute', targets)

            /**
             * Insert animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx spork-lift center-stg ${mode === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_bufficorns}/fx/spork-lift/spork-lift.png`;
            img.alt = 'spork-lift';
            fxDiv.appendChild(img);

            // Append the div to the center stage
            document.querySelector('#center-stage')?.appendChild(fxDiv);

            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the animation
                 */
                document.querySelector('.fx.spork-lift.center-stg')?.remove()

                resolve(true)

            }, 2500)

        }

    })
}

/**
 * Merge Conflict
 * @param c1 Card Sending Action
 * @param c2 Cards Receiving Damage
 * @returns Boolean
 */
export function mergeConflict(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values
            applyEffects('merge-conflict', mode, targets as HTMLElement[], undefined, 750, 750).then(() => {
                resolve(true)
            })

            // console.log('targets with attribute', targets)

            /**
             * Insert animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx merge-conflict center-stg ${mode === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_bufficorns}/fx/merge-conflict/merge-conflict.png`;
            img.alt = 'merge-conflict';
            fxDiv.appendChild(img);

            // Append the div to the center stage
            document.querySelector('#center-stage')?.appendChild(fxDiv);

            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the animation
                 */
                document.querySelector('.fx.merge-conflict.center-stg')?.remove()

                resolve(true)

            }, 2500)

        }

    })
}

/**
 * Power Up: Restore the Defense Value of a Selected Card
 * @param c Card selected
 * @returns Boolean
 */
export function sporkWhale(c: TokenMetadata) {
    return new Promise<boolean>((resolve) => {

        const target = document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`);
          
        if (target) {
            applyEffects('spork-whale', 'cardAbility', target).then(() => {
                resolve(true)
            })
        }

    })
}
