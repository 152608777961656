// useIsLargeScreen.ts
import { useEffect, useState } from 'react';

export const useIsLargeScreen = (): boolean => {
  const [isLarge, setIsLarge] = useState(window.matchMedia('(min-width: 64em)').matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 64em)');
    const handleMediaChange = () => {
      if (isLarge !== mediaQuery.matches) {
        setIsLarge(mediaQuery.matches);
      }
    };

    // Set initial state and add listener
    handleMediaChange();
    mediaQuery.addEventListener('change', handleMediaChange);

    // Cleanup listener on unmount
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, [isLarge]);

  return isLarge;
};
