// Config
import { CollectionSlug } from '../config/collections';

// React
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UserContext } from '../Context';

// Ionic
import { IonBadge, IonButton, IonContent, IonIcon, IonPage, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, chevronBack, chevronForward } from 'ionicons/icons';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Components
import PlayerCardDisplay from '../components/play/PlayerCardDisplay';
import CollectionButton from './CollectionButton';

// Utils
import { tilt } from '../Utils';

// Images
import { ReactComponent as WebsiteIcon} from './../images/icons/icon-website.svg';
import { ReactComponent as XLogo } from './../images/logos/logo-x.svg';
import { ReactComponent as OpenseaLogo } from './../images/logos/logo-opensea.svg';
import { ReactComponent as RaribleLogo } from './../images/logos/logo-rarible.svg';


const Collection: React.FC = () => {

    const { router, collections, user } = useContext<UserState>(UserContext);  
    const { collection } = useParams<{ collection: CollectionSlug }>();
    const [ collectionDetails ] = collections.filter((c) => c.slug === collection);

    // Pagination
    const { page } = useParams<{ page: string }>();
    const [collectionPageSize] = useState<number>(6);
    const [collectionPage, setCollectionPage] = useState<number>(parseInt(page) || 1);
    // Use Params to get page from loation

    const enableTiltAndScrollToTop = () => {
        setTimeout(() => {
            tilt('tilt-js-collection');

            const collectionContent = document.querySelector('.collection-content');
            collectionContent?.scrollTo({ top: 0, behavior: 'smooth' });

            const scrollContent = document.querySelector('.scrollable-page-content');
            scrollContent?.scrollTo({ top: 0, behavior: 'smooth' });

        }, 250);
    }

    // Collectio nChange
    useEffect(() => {
        if (!page) {
            setCollectionPage(1);
        }
    }, [collection, page])

    // Re-enable tilt
    useEffect(() => {
        enableTiltAndScrollToTop();
    }, [page, collection, collectionPage])

    return (
        <IonPage className="gladiator-page collection">
            <IonContent fullscreen className='transparent'>
                <div className="w-100 h-100 relative z-4">
                    <div className="page-content">
                        <div className="relative row full-width">
                            <div className="column small-12 large-3 flex justify-center justify-start-l animate__animated animate__fadeIn tl">
                                {collection ? (
                                <IonButton className="back-button" onClick={() => {
                                    router?.push('/collection');
                                }} color="primary" fill="clear">
                                    <IonIcon slot="icon-only" icon={arrowBack} color='dark' className='text-drop-shadow' />
                                    <span className="button-text f5 ml2 text-drop-shadow">Collections
                                    </span>
                                </IonButton>
                                ) : null}
                            </div>
                            <div className="column small-12 large-6 tc">
                                {collection ? (
                                    <h2 className='gladiator-arena animate__animated animate__fadeIn text-drop-shadow'>{collectionDetails ? collectionDetails?.name : 'Your Collection'}</h2>
                                ) : (
                                    <h2 className='gladiator-arena animate__animated animate__fadeIn text-drop-shadow'>Your Collection</h2>
                                )}
                            </div>
                            <div className="column small-12 large-3 flex justify-center justify-end-l items-center animate__animated animate__fadeIn">
                                <div className="links flex justify-center justify-end-l items-center text-drop-shadow mb2">
                                    {collectionDetails?.website ? (
                                        <a href={`${collectionDetails?.website}`} target="_blank" rel="noopener noreferrer">
                                            <WebsiteIcon className="website-icon" />
                                        </a>
                                    ) : null}
                                    {collectionDetails?.twitter ? (
                                        <a href={`${collectionDetails?.twitter}`} target="_blank" rel="noopener noreferrer">
                                            <XLogo className="x-logo" />
                                        </a>
                                    ) : null}
                                    {collectionDetails?.opensea ? (
                                        <a href={`${collectionDetails?.opensea}`} target="_blank" rel="noopener noreferrer">
                                            <OpenseaLogo className="opensea-logo" />
                                        </a>                                    
                                    ) : null}                                    
                                    {collectionDetails?.rarible ? (
                                        <a href={`${collectionDetails?.rarible}`} target="_blank" rel="noopener noreferrer">
                                            <RaribleLogo className="rarible-logo" />
                                        </a>
                                    ) : null}
                                </div>
                            </div>

                                    {/* Pagination */}
                                    {(collectionDetails) && 
                                        (user?.collectibles
                                            // Filter the collectibles by the selected collection
                                            .filter(c => c.collection === collection)
                                            // Check if the number of collectibles is greater than the page size
                                            .length > collectionPageSize
                                        )
                                         ? (
                                        <div className="column small-12 flex justify-center items-center content-center pb2">
                                            <IonButton id='prev-page' color={`primary`} className='relative z-2 mr2' size='small'

                                                /**
                                                 * Disable the previous page button if the user is on the first page
                                                 */
                                                disabled={collectionPage <= 1}
                                                onClick={() => {
                                                    if (collectionPage > 1) {
                                                        const page = collectionPage - 1;
                                                        router?.push(`/collection/${collection}/pg/${page}`);
                                                        setCollectionPage(page);
                                                    }
                                                }}
                                            >
                                                <span className="show-for-sr">Previous Page</span>                                           
                                                <IonIcon
                                                    icon={chevronBack}
                                                    color='dark'
                                                    className='animate__animated animate__zoomIn'
                                                    size='small'
                                                    style={{
                                                        marginInlineStart: 0,
                                                        marginInlineEnd: 0,
                                                    }}
                                                />
                                            </IonButton>

                                            <IonBadge 
                                                color="primary" 
                                                className="page-badge mt1" 
                                                onClick={() => {
                                                    const totalPages = Math.ceil(user?.collectibles.filter(c => c.collection === collection).length / collectionPageSize);
                                                    const nextPage = collectionPage < totalPages ? collectionPage + 1 : 1;
                                                    setCollectionPage(nextPage);
                                                    router?.push(`/collection/${collection}/pg/${nextPage}`);
                                                }}
                                            >
                                                {collectionPage} / {Math.ceil(
                                                    // Divide the number of collectibles by the page size to get the number of pages
                                                    user?.collectibles
                                                        ?.filter(c => c.collection === collection).length / collectionPageSize
                                                )}
                                            </IonBadge>

                                            <IonButton id='next-page' color={`primary`} className='relative z-2 ml2' size='small'

                                                /**
                                                 * Disable the next page button if the user is on the last page
                                                 */
                                                disabled={
                                                    user?.collectibles
                                                        ?.filter(c => c.collection === collection).length <= collectionPageSize * collectionPage
                                                }
                                                onClick={() => {
                                                    if (user?.collectibles.filter(c => c.collection === collection).length > collectionPageSize * collectionPage) {
                                                        const page = collectionPage + 1;
                                                        setCollectionPage(page);
                                                        router?.push(`/collection/${collection}/pg/${page}`);
                                                    }
                                                }}
                                            >
                                                <span className="show-for-sr">Next Page</span>                                           
                                                <IonIcon
                                                    icon={chevronForward}
                                                    color='dark'
                                                    className='animate__animated animate__zoomIn'
                                                    size='small'
                                                    style={{
                                                        marginInlineStart: 0,
                                                        marginInlineEnd: 0,
                                                    }}
                                                />
                                            </IonButton>
                                        </div>                                        
                                    ) : null}

                        </div>

                        <div className="relative row scrollable-page-content">
                            <div className="gladiator-top-border" />
                            {/* <div className="gladiator-bottom-border" /> */}
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center pb6">

                                {/* Ion fab buttons for collection options */}
                                {!collection ? (
                                    <div className="collection-options w-100 flex flex-wrap justify-center">
                                        {collections.map((c, i) => {
                                            return (
                                                <CollectionButton 
                                                    key={`collection-button-${i}`}
                                                    collection={c}
                                                    selected={collection}
                                                    setCollection={() => {
                                                        setTimeout(() => {
                                                            router?.push(`/collection/${c.slug}`);
                                                        }, 350)
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                ) : null}

                                {/* Collection Banner */}
                                {/* {collection && collectionDetails ? (
                                    <div className="db w-100 flex justify-center items-center animate__animated animate__fadeIn">
                                        <div className="inner-block relative w-90 flex justify-center items-center mh2 mh5-l"
                                            style={{
                                                backgroundImage: `url('${collectionDetails?.bgImage}')`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            <div className="border-accent outer z-0"></div>
                                            {collectionDetails.logo ? (
                                                <img src={collectionDetails?.logo} alt="" className='logo mv4' style={{ width: '300px', height: '250px', objectFit: 'contain' }} />
                                            ): (
                                                <h2 className="logo gladiator-arena f4">{collectionDetails?.name}</h2>
                                            )}
                                        </div>
                                    </div>
                                ) : null} */}

                                {collection ? (
                                    <div className="w-100 db flex flex-wrap justify-center collection-container">

                                        {/* Collection Cards */}
                                        {collection && 
                                            user?.collectibles
                                                .filter(c => c.collection === collection)
                                                // Paginate the collectibles
                                                ?.slice((collectionPage - 1) * collectionPageSize, collectionPage * collectionPageSize)
                                                .map((m, i) => {
                                            return (
                                            <div key={`collectible-${i}_${collectionPage}`}  className='player-card-container pa3 pointer'>
                                                <PlayerCardDisplay card={m} click={() => {
                                                    router?.push(`/collection/${m.collection}/${m.token_id}`)
                                                }} />
                                            </div>
                                            )
                                        })}

                                        {/* No Collection Message */}
                                        {collection && !user?.collectibles.filter(c => c.collection === collection).length ? (
                                            <div className="w-100 tc animate__animated animate__zoomIn">
                                                <h3 className='gladiator-arena'>You don't have any {collectionDetails?.name} yet!</h3>
                                                <IonButton color={'primary'} onClick={() => {
                                                    window.open(`${collectionDetails?.opensea}`, '_blank');
                                                }}>
                                                    <span className="dib gladiator-arena flex items-center">
                                                        <span className="mt1 pt2 mr2">Get {collectionDetails?.name}</span>
                                                        <OpenseaLogo className="opensea-logo" style={{ width: '20px', height: '20px' }} />
                                                    </span>
                                                </IonButton>
                                            </div>
                                        ) : null}

                                    </div>
                                ) : null}

                            </div>
                        </div>
                    </div>
                </div>
                <div id="tilt-js-collection" />
            </IonContent>
        </IonPage>
    );
};

export default Collection;
