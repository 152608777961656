import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { apexStrike, chaoticLash, corruptionWave, dominanceProtocol } from "./fx";
import { ReactComponent as ApexStrike } from '../../../collections/bedlams/abilities/bedlams_apex-strike.svg';
import { ReactComponent as ChaoticLash } from '../../../collections/bedlams/abilities/bedlams_chaotic-lash.svg';
import { ReactComponent as CorruptionWave } from '../../../collections/bedlams/abilities/bedlams_corrupted-wave.svg';
import { ReactComponent as DominanceProtocol } from '../../../collections/bedlams/abilities/bedlams_dominance-protocol.svg';

const abilities: AbilityLib[] = [
    {
        "name": "Dominance Protocol",
        "power": 75,
        "energy": 3,
        "group": false,
        "type": AbilityType.HealDefense,
        "self": true,
        "description": "Assert dominance by raising your defenses, to intimidate your enemies.",
        "cssClass": "dominance-protocol",
        "icon": <DominanceProtocol />,
        "method": dominanceProtocol,
        "sound": {
            "title": "Dominance Protocol",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx/dominance-protocol/DominanceProtocol.mp3",
            "soundsLike": ""
        }
    },
    {
        "name": "Chaotic Lash",
        "power": 50,
        "energy": 2,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Unleash a torrent of madness, spreading confusion and devastation to a single enemy.",
        "cssClass": "chaotic-lash",
        "icon": <ChaoticLash />,
        "method": chaoticLash,
        "sound": {
            "title": "Chaotic Lash",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx/chaotic-lash/ChaoticLash.mp3",
            "soundsLike": "Three machine impacts and scraping"
        }
    },
    {
        "name": "Apex Strike",
        "power": 75,
        "energy": 5,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Channel the primal power of the ancient beasts, delivering a single devastating blow.",
        "cssClass": "apex-strike",
        "icon": <ApexStrike />,
        "method": apexStrike,
        "sound": {
            "title": "Apex Strike",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx/apex-strike/ApexStrike.mp3",
            "soundsLike": "A lion roar, a whip crack, and a heavy impact"
        }
    },
    {
        "name": "Corruption Wave",
        "power": 40,
        "energy": 4,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Unleash a wave of corruption, decimating your enemies.",
        "cssClass": "corruption-wave",
        "icon": <CorruptionWave />,
        "method": corruptionWave,
        "sound": {
            "title": "Corruption Wave",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx/corruption-wave/CorruptionWave.mp3",
            "soundsLike": "A wave of energy and a heavy impact"
        }
    }
]

export default abilities;