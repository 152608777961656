import { objectPath_sacredmoths } from "..";
import { applyEffects, calculateAngleAndDistance, calculateTranslation } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";
import { gsap } from "gsap";

/**
 * Range Attack: Shooting Laser Across Screen with Player and Opponent Animations
 * @param c1 Card Sending Action
 * @param c2 Target Card
 * @param mode Ability Mode
 */
export function galacticDustBeam(c1: TokenMetadata, c2: TokenMetadata, mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const tl = gsap.timeline();
        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);
    
        if (source && target) {

            /**
             * Prepare laser DOM element for source
             */
            const laserDiv = document.createElement('div');
            laserDiv.id = `laser_${c1.token_id}`
            laserDiv.className = `laser-beam cardAbility ${mode === 'cardAbility' ? `attack` : `incoming`}`;
            source.appendChild(laserDiv);
            const laser = document.getElementById(laserDiv.id);
    
            /**
             * Prepare angle and distance
             */
            const angleAndDistance = calculateAngleAndDistance(
                source,
                target,
                mode
            );
    
            /**
             * Position the laser at the source
             */
            gsap.set(laser, {
                height: '0px',
                rotation: angleAndDistance.angleDeg + 'deg'
            });
    
            /**
             * Animate the laser
             */
            tl.to(laser, {
                height: angleAndDistance.distance + 'px',
                duration: 0.26,
            }).then(() => {
    
                applyEffects('galactic-dust-beam', mode, target, source, 1250, 300).then(() => {
                    laser?.remove();
                    resolve(true)
                })
    
            });
    
        }

    })

}

/**
 * Group Attack: Hyperdrive Flare Effect
 * @param c1 Card Sending Action
 * @param c2 Cards Receiving Damage
 * @returns Boolean
 */
export function hyperdriveFlare(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values
            applyEffects('hyperdrive-flare', mode, targets as HTMLElement[], undefined, 750, 300).then(() => {
                resolve(true)
            })

            /**
             * Insert animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx hyperdrive-flare center-stg ${mode === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_sacredmoths}/fx/hyperdrive-flare/HyperdriveFlare.png`;
            img.alt = 'hyperdrive-flare';
            fxDiv.appendChild(img);

            document.querySelector('#center-stage')?.appendChild(fxDiv);


            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the animation
                 */
                document.querySelector('.fx.hyperdrive-flare.center-stg')?.remove()

                resolve(true)

            }, 1900)

        }

    })
}

/**
 * Range Attack: Shooting Orb Across Screen with Player and Opponent Animations
 * @param c1 Card Sending Action
 * @param c2 Card Receiving Damage
 * @param mode Ability Mode
 * @returns 
 */
export function nebulaPulse(c1: TokenMetadata, c2: TokenMetadata, mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);

        if (source && target) {

            /**
             * Prepare projectile DOM element for source
             */
            const projectile = document.createElement('div');
            projectile.id = `nebula-pulse-orb_${c1.token_id}`
            projectile.className = `nebula-pulse-orb cardAbility ${mode === 'cardAbility' ? `attack` : `incoming`}`;
            source.appendChild(projectile);
            const orb = document.getElementById(projectile.id);
    
            if (orb)  {

                const {
                    translateX,
                    translateY
                } = calculateTranslation(source, target);
        
                setTimeout(() => {
                    orb.style.transform = `translate(${translateX}px, ${translateY}px)`;
                }, 500)

                applyEffects('nebula-pulse', mode, target, source, 1250, 300, 1000).then(() => {
                    orb?.remove();
                    resolve(true)
                })

            }
    
        }

    });
}

/**
 * Power Up: Restore the Defense Value of a Selected Card
 * @param c Card selected
 * @returns Boolean
 */
export function stardustRevive(c: TokenMetadata) {
    return new Promise<boolean>((resolve) => {

        const target = document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`);
          
        if (target) {
            applyEffects('stardust-revive', 'cardAbility', target, undefined, 1200).then(() => {
                resolve(true)
            })
        }

    })
}