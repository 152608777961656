// Types
import { AbilityClasses, AbilityMode } from "../types/Abilities.type";

// Interfaces
import { CalculateAngleAndDistance, CalculateTranslation } from "../interfaces/FX.interface";

// Colyseus
import { TokenMetadata } from "../generated/TokenMetadata";

/**
 * Apply Visual Effects to Card Elements
 * @param cssClass Ability CSS class
 * @param mode Ability context
 * @param target Ability target
 * @param source Ability source
 * @param timeout Cleanup delay (default: 750)
 * @param targetCleanupDelay Target cleanup delay
 * @param targetDelay Target delay
 */
export function applyEffects(
    cssClass: AbilityClasses,
    mode: AbilityMode,
    target: HTMLElement | HTMLElement[],
    source?: HTMLElement,
    timeout: number = 750,
    targetCleanupDelay: number = 0,
    targetDelay = 0
) {
    return new Promise((resolve) => {

        // Console log all the things
        // console.log('applyEffects', cssClass, mode, target, source, timeout, targetCleanupDelay, targetDelay)

        // Source
    
            if (source) {

                /**
                 * Display player effects
                 */
                source.setAttribute('data-battle-fx', `${cssClass}`)
                source.setAttribute('data-battle-fx-mode', mode)
    
                /**
                 * Insert player starting animation DOM element
                 */
                const sourceFX = document.createElement('div');
                sourceFX.className = `fx ${cssClass}`;
                sourceFX.setAttribute('key', `${Date.now()}`)

                source.querySelector('.aspect-ratio--object')?.appendChild(sourceFX);

            }

            setTimeout(() => {

                // Target
                if (target instanceof Array) {
    
                    /**
                     * Display battle damage effect to the target
                     */
                    target.map(t => t.setAttribute('data-battle-fx', `${cssClass}`))
                    target.map(t => t.setAttribute('data-battle-fx-mode', mode))
                    target.map(t => t.setAttribute('key', `${Date.now()}`))
    
                    /**
                     * Append effects DOM element
                     */
                    target.forEach(t => {
                        const targetFX = document.createElement('div');
                        targetFX.className = `fx ${cssClass}`;
                        t.querySelector('.aspect-ratio--object')?.appendChild(targetFX)
                    });
    
                } else {
    
                       
                    /**
                     * Display battle damage effect to the target
                     */
                    target.setAttribute('data-battle-fx', `${cssClass}`)
                    target.setAttribute('data-battle-fx-mode', mode)
                    target.setAttribute('key', `${Date.now()}`)
            
                    /**
                     * Append effects DOM element
                     */
                    const targetFX = document.createElement('div');
                    targetFX.className = `fx ${cssClass}`;
                    target.querySelector('.aspect-ratio--object')?.appendChild(targetFX);
    
                }
            
            }, targetDelay);

        // Cleanup
        setTimeout(() => {
            removeEffects(cssClass, target, targetCleanupDelay, source)
            resolve(true)
        }, timeout)

    })
}

/**
 * Remove Visual Effects from Card Elements
 * @param cssClass Ability CSS class
 * @param mode Ability context
 * @param target Ability target
 * @param source Ability source
 */
function removeEffects(
    cssClass: AbilityClasses,
    target: HTMLElement | HTMLElement[],
    targetCleanupDelay: number,
    source?: HTMLElement,
) {

    // SOURCE

        /**
         * Remove the starting animation from the player
         */
        source?.querySelector(`.fx.${cssClass}`)?.remove()

        /**
         * Remove effects from the player
         */
        source?.removeAttribute('data-battle-fx')
        source?.removeAttribute('data-battle-fx-mode')

    // TARGET

    setTimeout(() => {

        if (target instanceof Array) {

            /**
             * Remove DOM elements from the target
             */
            target.map(t => t.querySelectorAll(`.fx.${cssClass}`)?.forEach(el => el.remove()))
                
            /**
             * Remove effects from the target
             */
            target.map(t => t.removeAttribute('data-battle-fx'))
            target.map(t => t.removeAttribute('data-battle-fx-mode'))

        } else {

            /**
             * Remove DOM elements from the target
             */
            target.querySelectorAll(`.fx.${cssClass}`)?.forEach(el => el.remove())
    
            /**
             * Remove effects from the target
             */
            target.removeAttribute('data-battle-fx')
            target.removeAttribute('data-battle-fx-mode')

        }


    }, targetCleanupDelay)
    
}

/**
 * Calculate the Distance Between Two DOM Elements
 * @param element1 DOM Element 1
 * @param element2 DOM Element 2
 * @returns X and Y coordinates
 */
export function calculateTranslation(element1: HTMLElement, element2: HTMLElement): CalculateTranslation {
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();

    const translateX = rect2.left - rect1.left;
    const translateY = rect2.top - rect1.top;

    return {
        translateX,
        translateY
    };
}

/**
 * Calculate Angle Between Two DOM Elements
 * @param element1 DOM Element 1
 * @param element2 DOM Element 2
 * @param mode Context of the ability
 * @returns 
 */
export function calculateAngleAndDistance(element1: HTMLElement, element2: HTMLElement, mode: AbilityMode): CalculateAngleAndDistance {


    const sourcePos = element1.getBoundingClientRect();
    const targetPos = element2.getBoundingClientRect();

    // Setup angle deltas
    let deltaY = 0;
    let deltaX = 0;
    let angleDeg = 0;

    /**
     * Depending on the context / direciton of the attack,
     * our starting point delta calculations need to be 
     * inversed for the effect to appear right for both
     * players.
     */
    if (mode === 'cardAbility') {
        deltaY = sourcePos.top - targetPos.top;
        deltaX = targetPos.left - sourcePos.left;
    }
    if (mode === 'incoming') {
        deltaY = targetPos.top - sourcePos.top;
        deltaX = sourcePos.left - targetPos.left;
    }

    /**
     * The angle calculation. Looks simple. Was a doozy.
     */
    angleDeg = (Math.atan2(deltaY, deltaX) * 180 / Math.PI - 90 + 360) % 360;
    angleDeg = angleDeg * -1;

    /**
     * Calculate the distance between source and target from their centers
     */
    let distance = Math.sqrt(Math.pow(targetPos.top - sourcePos.top, 2) + Math.pow(targetPos.left - sourcePos.left, 2));

    return {
        deltaY,
        deltaX,
        angleDeg,
        distance
    }

}

/**
 * Death Animation
 * @param c Card selected
 * @returns Boolean
 */
export function death(c: TokenMetadata) {
    return new Promise<boolean>((resolve) => {

        const target = document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`);
          
        if (target) {
            setTimeout(() => {

                target.setAttribute('data-battle-fx', 'death')
                setTimeout(() => {   
                    resolve(true)
                }, 500)

            }, 500)
        }

    })
}
