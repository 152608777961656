import { objectPath_cosmicmuffins } from "..";
import { applyEffects, calculateAngleAndDistance, calculateTranslation } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";
import { gsap } from "gsap";

/**
 * Melee Attack: Card Moves Across Screen
 * @param c1 Card Sending Action
 * @param c2 Target Card
 */
export function muffinMelee(c1: TokenMetadata, c2: TokenMetadata) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);
    
        if (source && target) {
            const {
                translateX,
                translateY
            } = calculateTranslation(source, target);
    
            source.style.transform = `translate(${translateX}px, ${translateY}px)`;
            applyEffects('muffin-melee-attack', 'cardAbility', target, source).then(() => {
                source.style.transform = '';
                resolve(true)
            })
    
        }

    })

}

/**
 * Range Attack: Shooting Laser Across Screen
 * @param c1 Card Sending Action
 * @param c2 Target Card
 * @param mode Ability mode
 */
export function cosmicLaser(c1: TokenMetadata, c2: TokenMetadata, mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const tl = gsap.timeline();
        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);
    
        if (source && target) {

            /**
             * Prepare laser DOM element for source
             */
            const laserDiv = document.createElement('div');
            laserDiv.id = `laser_${c1.token_id}`
            laserDiv.className = `laser-beam cardAbility ${mode === 'cardAbility' ? `attack` : `incoming`}`;
            source.appendChild(laserDiv);
            const laser = document.getElementById(laserDiv.id);

            /**
             * Prepare angle and distance
             */
            const angleAndDistance = calculateAngleAndDistance(
                source,
                target,
                mode
            );

            /**
             * Position the laser at the source
             */
            gsap.set(laser, {
                height: '0px',
                rotation: angleAndDistance.angleDeg + 'deg'
            });
    
            /**
             * Animate the laser
             */
            tl.to(laser, {
                height: angleAndDistance.distance + 'px',
                duration: 0.26,
            }).then(() => {
    
                applyEffects('cosmic-laser', mode, target, source).then(() => {
                    laser?.remove();
                    resolve(true)
                })
    
            });
    
        }

    })

}

/**
 * Group Attack: Butter Wave Locking All Opponent Cards for One Turn
 * @param c1 Card Sending Action
 * @param c2 Cards Receiving Damage
 * @returns Boolean
 */
export function butterBlast(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values
            applyEffects('butter-blast-attack', mode, targets as HTMLElement[], undefined, 750, 750).then(() => {
                resolve(true)
            })

            // console.log('targets with attribute', targets)

            /**
             * Insert butter blast animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx butter-blast center-stg ${mode === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_cosmicmuffins}/fx/butter-blast/butterTransition_animated-vert.png`;
            img.alt = 'butter-blast';
            fxDiv.appendChild(img);

            // Append the div to the center stage
            document.querySelector('#center-stage')?.appendChild(fxDiv);

            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the butter blast animation
                 */
                document.querySelector('.fx.butter-blast.center-stg')?.remove()

                resolve(true)

            }, 2500)

        }

    })
}

/**
 * Group Attack: Electric Blast from a Floating Astronaut
 * @param c1 Card Sending Action
 * @param c2 Cards Receiving Damage
 * @returns Boolean
 */
export function electronaut(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            document.querySelector('.fx.electronaut')?.remove()

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values
            targets.map(t => t?.setAttribute('data-battle-fx', 'electronaut-attack'))

            // console.log('targets with attribute', targets)

            /**
             * Insert electronaut animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx electronaut ${mode === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_cosmicmuffins}/fx/electronaut/electronaut-bg_animated.png`;
            img.alt = 'electronaut';
            fxDiv.appendChild(img);

            // Append the astronaut after the electronaut
            const astronaut = document.createElement('img');
            astronaut.src = `${objectPath_cosmicmuffins}/fx/electronaut/astronaut_animated.png`;
            astronaut.className = 'astronaut';
            fxDiv.appendChild(astronaut);

            document.querySelector('#center-stage')?.appendChild(fxDiv);

            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the butter blast animation
                 */
                document.querySelector('.fx.electronaut')?.remove()

                /**
                 * Remove battle damage effect class from the target
                 */
                targets.map(t => t?.removeAttribute('data-battle-fx'))

                resolve(true)

            }, 2000)

        }

    })
}

/**
 * Power Up: Restore the Defense Value of a Selected Card
 * @param c Card selected
 * @returns Boolean
 */
export function crumbleCure(c: TokenMetadata) {
    return new Promise<boolean>((resolve) => {

        const target = document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`);
          
        if (target) {
            applyEffects('crumble-cure', 'cardAbility', target).then(() => {
                resolve(true)
            })
        }

    })
}
