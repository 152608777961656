import React, { useState, useRef } from 'react';
import { IonInput } from '@ionic/react';

const OtpInput: React.FC<{ onComplete: (code: string) => void }> = ({ onComplete }) => {

    // OTP state
    const [otp, setOtp] = useState<string[]>(Array(6).fill(''));

    // Input refs
    const inputsRef = useRef<HTMLInputElement[]>([]);

    /**
     * Handle input change
     * @param value 
     * @param index 
     */
    const handleChange = async (value: string, index: number) => {

        // Enforce single-digit input
        if (/^\d$/.test(value)) {

            // Update state
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            console.log('newOtp', newOtp)
            console.log('index', index)

            // Move to the next input if available
            if (index < 5) {
                const nextInput = inputsRef.current[index + 1];
                if (nextInput) {
                    // Get native input from Ionic input
                    const nativeInput = await (nextInput as any).getInputElement();
                    nativeInput.focus();
                }
            }

            // Trigger onComplete if all fields are filled
            if (newOtp.every((digit) => digit !== '')) {
                onComplete(newOtp.join(''));
            }

        // Handle backspace
        } else if (value === '') {

            // Allow clearing the field
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            
        }
    };

    /**
     * Handle paste
     * @param e 
     * @param index 
     */
    const handlePaste = async (e: React.ClipboardEvent, index: number) => {

        // Prevent default paste behavior
        e.preventDefault();

        // Extract and sanitize paste data
        const pasteData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6);
        const newOtp = [...otp];

        // Split and insert paste data
        pasteData.split('').forEach((digit, i) => {
            if (index + i < 6) {
                newOtp[index + i] = digit;
            }
        });

        // Update state
        setOtp(newOtp);

        // Move focus to the last filled input
        const nextIndex = Math.min(index + pasteData.length, 5);

        // Get native input from Ionic input
        const nextInput = inputsRef.current[nextIndex];

        // Get native input from Ionic input
        const nativeInput = await (nextInput as any).getInputElement();
        nativeInput.focus();
        
        // Trigger onComplete if all fields are filled
        if (newOtp.every((digit) => digit !== '')) {
            onComplete(newOtp.join(''));
        }

    };

    /**
     * Handle keydown
     * @param e 
     * @param index 
     */
    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            
            // Move focus to the previous input
            const prevInput = inputsRef.current[index - 1];
            
            // Get native input from Ionic input
            (prevInput as any).getInputElement().then((nativeInput: HTMLInputElement) => {
                nativeInput.focus();
            });

        }
    };

    return (
        <div style={{ 
            display: 'flex',
            gap: '8px', 
            justifyContent: 'center', 
            marginTop: '20px', 
            maxWidth: '333px'
        }}>
            {otp.map((digit, index) => (
                <span key={index} className="gladiator-input-wrapper">
                    <IonInput
                        ref={(el) => (inputsRef.current[index] = el as unknown as HTMLInputElement)}
                        value={digit}
                        fill="outline"
                        type="tel"
                        maxlength={1}
                        className="w-100 gladiator-input pt-serif otp"
                        inputMode="numeric"
                        onIonInput={(e) => handleChange(e.detail.value!, index)}
                        onPaste={(e) => handlePaste(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        style={{
                            fontSize: '1.5rem',
                            textAlign: 'center',
                            width: '40px',
                            height: '50px',
                        }}
                    />
                </span>
            ))}
        </div>
    );
};

export default OtpInput;
