/**
 * This file contains the PlayContextProvider component, which provides the PlayContext to its children.
 * The PlayContext stores the state and methods related to the gameplay, such as the player's tokens and cards,
 * the opponent's card, the selected ability, and the played cards and abilities.
 * It also handles the communication with the server.
 */

// React
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../Context";

// Ionic
import { Storage } from '@ionic/storage';
import { IonAlert,IonLoading, IonSpinner } from "@ionic/react";

// Interfaces
import { UserState } from "../interfaces/User.interface";

// Types
import { BackdropTypes } from "../types/Backdrops.type";
import { AbilityMode } from "../types/Abilities.type";

// Services
import { ability, click, connectToGameServer, getRooms, leave, rematchAccepted, rematchDecline } from "../services/GameServer";

// Components
import Header from "../components/ui/Header";
import BottomNav from "../components/ui/BottomNav";
import GetReady from "./GetReady";
import { cardDefaultDefense, cardDefaultHealth } from "../components/play/PlayerCard";

// FX
import { evade } from "../fx/fx-battle";

// Util
import { checkOwnership, decompressData } from "../Utils";

// Sound FX Context and Component
import { useVolume } from "../VolumeContext";
import Sound from "../components/play/Sound";

// Colyseus
import { Room, RoomAvailable } from "colyseus.js";
import { TokenMetadata } from "../generated/TokenMetadata";
import { Ability } from "../generated/Ability";
import { BattleRoomState } from "../generated/BattleRoomState";
import { Player } from "../generated/Player";
import { ShopItem } from "../generated/ShopItem";
import { Stats } from "../generated/Stats";
import { GameSummary } from "../interfaces/GameSummary.interface";
import { ShopItemRow } from "../supabase/types";
import { ShopItemMetadata } from "../interfaces/ShopItem.interface";

// Prepare local storage
const storage = new Storage();
storage.create();

export interface PlayState {

    // Lobby
    lobby: Room | null | undefined;

    // Rooms
    rooms: RoomAvailable[];
    room: Room | null;
    roomCreated: boolean;
    battleRoomState: BattleRoomState | null;

    // Game started
    requiredTokens: number;
    gameStart: any;

    // Player
    player: Player | null;
    loadingToken: boolean;
    prepareToken: TokenMetadata | null | undefined;
    frame: ShopItemRow | null | undefined;
    wearable: ShopItemRow | null | undefined;
    myTokens: TokenMetadata[];
    myCards: TokenMetadata[];
    myCard: TokenMetadata | null | undefined;
    myAbilities: Ability[];

    // Opponent
    opponent: Player | null;
    opponentCard: TokenMetadata | null | undefined;
    opponentAbilities: Ability[];

    // Abilities
    abilitySelected: Ability | null | undefined;
    played: string[];
    cardAbilityPlayed: Ability | null;
    displayAbilityPlayed: boolean;

    // Gameplay
    displayYourTurn: boolean;
    displayEvaded: boolean;
    displayLose: boolean;
    displayWin: boolean;
    displayTie: boolean;
    otherWalletAddress: string | number | null | undefined;
    otherPlayerUnavailable: boolean;
    otherPlayerConnected: string | null;
    otherPlayerLeft: boolean;
    opponentEnergy: number | undefined;
    opponentCardHover: TokenMetadata | null | undefined;
    opponentCards: TokenMetadata[];
    chooseTokens: boolean;
    randomBackdrop: BackdropTypes | undefined;
    myTurn: boolean | null;
    myEnergy: number | undefined;
    timerValue: number;
    turnCount: number;
    turnMax: number;
    gameOver: boolean;
    gameSummary: GameSummary | undefined;

    // Gameplay
    fetchRooms: () => void;
    setRoom: (room: Room | null) => void;
    setOtherWalletAddress: (wallet: string | number | null | undefined) => void;
    setServerUnavailable: (boolean: boolean) => void;
    setAwaitingRematchResponse: (boolean: boolean) => void;
    setDisplayYourTurn: (boolean: boolean) => void;
    resetGame: (chooseTokens: boolean) => void;
    setGameSummary: (msg: GameSummary | undefined) => void;

    // Get Ready
    setLoadingToken: (boolean: boolean) => void;
    setPrepareToken: (token: TokenMetadata | null) => void;
    setFrame: (frame: ShopItemRow | null) => void;
    setWearable: (wearable: ShopItemRow | null) => void;
    toggleTokenSelect: (token: TokenMetadata  | null | undefined) => void;
    updateToken: (token: TokenMetadata) => void;
    isReady: (token: TokenMetadata | null | undefined) => boolean;
    isEquipmentAttachedToAnyTeamMember: (equipment: ShopItemRow) => boolean;
    isThisEquipmentAttachedToThisToken: (token: TokenMetadata, equipment: ShopItemRow) => boolean;
    
    // Player
    setMyCard: (card: TokenMetadata | null) => void;
    selectAbility: (ability: Ability, card: TokenMetadata) => void;
    selfClick: (card: TokenMetadata) => void;
    opponentClick: (card: TokenMetadata) => void;

}

const defaultPlayState: PlayState = {

    // State
    lobby: null,
    rooms: [],
    room: null,
    roomCreated: false,
    battleRoomState: null,
    requiredTokens: 3,
    gameStart: null,

    // Player
    player: null,
    loadingToken: false,
    prepareToken: null,
    frame: null,
    wearable: null,
    myTokens: [],
    myCards: [],
    myCard: null,
    myAbilities: [],

    // Opponent
    opponent: null,
    opponentCard: null,
    opponentAbilities: [],

    // Abilities
    abilitySelected: null,
    played: [],
    cardAbilityPlayed: null,
    displayAbilityPlayed: false,
    displayEvaded: false,
    
    // Gameplay
    displayYourTurn: false,
    displayLose: false,
    displayWin: false,
    displayTie: false,
    otherWalletAddress: null,
    otherPlayerUnavailable: false,
    otherPlayerConnected: null,
    otherPlayerLeft: false,
    opponentEnergy: 0,
    opponentCardHover: null,
    opponentCards: [],
    chooseTokens: false,
    randomBackdrop: undefined,
    myTurn: null,
    myEnergy: 0,
    timerValue: 0,
    turnCount: 0,
    turnMax: 0,
    gameOver: false,
    gameSummary: undefined,

    // Gameplay
    fetchRooms: () => {},
    setRoom: (room: Room | null) => {},
    setOtherWalletAddress: (wallet: string | number | null | undefined) => {},
    setServerUnavailable: (boolean: boolean) => {},
    setAwaitingRematchResponse: (boolean: boolean) => {},
    setDisplayYourTurn: (boolean: boolean) => {},
    resetGame: (chooseTokens: boolean) => {},
    setGameSummary: (msg: GameSummary | undefined) => {},

    // Get Ready
    setLoadingToken: (boolean: boolean) => {},
    setPrepareToken: (token: TokenMetadata | null) => {},
    setFrame: (frame: ShopItemRow | null) => {},
    setWearable: (wearable: ShopItemRow | null) => {},
    toggleTokenSelect: (token: TokenMetadata | null | undefined) => {},
    updateToken: (token: TokenMetadata) => {},
    isReady: (token: TokenMetadata | null | undefined) => false,
    isEquipmentAttachedToAnyTeamMember: (equipment: ShopItemRow) => false,
    isThisEquipmentAttachedToThisToken: (token: TokenMetadata, equipment: ShopItemRow) => false,

    // PLayer
    setMyCard: (card: TokenMetadata | null) => {},
    selectAbility: (ability: Ability, card: TokenMetadata) => {},
    opponentClick: (card: TokenMetadata) => {},
    selfClick: (card: TokenMetadata) => {},

}

export const PlayContext = React.createContext<PlayState>(defaultPlayState);

const PlayContextProvider: React.FC<any> = ({ children }) => {

    const { 
        router, 
        testnet, 
        user, 
        tokensLoaded, 
        collectiblesLoaded, 
        setSocket, 
        collections,
        abilities,
        musicPlayer, 
        audioPlaying, 
        balanceCheck, 
        playerStats 
    }: UserState = useContext(UserContext);

    const [requiredTokens, setRequiredTokens] = useState<number>(3)

    // Server connect alert
    const [serverUnavailable, setServerUnavailable] = useState<boolean>(false);

    // Rooms
    const [lobby, setLobby] = useState<Room | null>();
    const [rooms, setRooms] = useState<RoomAvailable[]>([]);
    const [room, setRoom] = useState<Room | null>(null);
    const [roomCreated, setRoomCreated] = useState<boolean>(false);

    // Gameplay: Choose Tokens to Play With
    const [chooseTokens, setChooseTokens] = useState<boolean>(false)

    // Gameplay: Get Ready
    const [loadingToken, setLoadingToken] = useState<boolean>(false)
    const [prepareToken, setPrepareToken] = useState<TokenMetadata | null>(null)
    const [frame, setFrame] = useState<ShopItemRow | null>(null)
    const [wearable, setWearable] = useState<ShopItemRow | null>(null)

    // Gameplay: Backdrop
    const [randomBackdrop, setRandomBackdrop] = useState<BackdropTypes>()

    // Gameplay: Game Start
    const [gameStart, setGameStart] = useState<any>(null);

    // Gameplay: Player
    const [player, setPlayer] = useState<Player | null>(null)
    const [myTokens, setMyTokens] = useState<TokenMetadata[]>([])
    const [myCards, setMyCards] = useState<TokenMetadata[]>([])
    const [myCard, setMyCard] = useState<TokenMetadata | null>()
    const [myEnergy, setMyEnergy] = useState<number>()
    const [abilitySelected, setAbilitySelected] = useState<Ability | null>();
    const [myAbilities, setMyAbilities] = useState<Ability[]>([]);

    // Gameplay: Opponent
    const [opponent, setOpponent] = useState<Player | null>(null)
    const [otherWalletAddress, setOtherWalletAddress] = useState<string | number | null | undefined>()
    const [otherPlayerUnavailable, setOtherPlayerUnavailable] = useState<boolean>(false)
    const [otherPlayerConnected, setOtherPlayerConnected] = useState<string | null>(null)
    const [otherPlayerLeft, setOtherPlayerLeft] = useState<boolean>(false)
    const [opponentCards, setOpponentCards] = useState<TokenMetadata[]>([]);
    const [opponentCard, setOpponentCard] = useState<TokenMetadata | null>()
    const [opponentEnergy, setOpponentEnergy] = useState<number>()
    const [opponentCardHover, setOpponentCardHover] = useState<TokenMetadata | null>()
    const [opponentAbilities, setOpponentAbilities] = useState<Ability[]>([]);

    // Gameplay: Ability Played
    const [played, setPlayed] = useState<string[]>([]);
    const [cardAbilityPlayed, setCardAbilityPlayed] = useState<Ability | null>(null);
    const [displayAbilityPlayed, setDisplayAbilityPlayed] = useState<boolean>(false);

    // Gameplay: Turns
    const [timerValue, setTimerValue] = useState<number>(0);
    const [turnCount, setTurnCount] = useState<number>(0);
    const [turnMax, setTurnMax] = useState<number>(0);
    const [myTurn, setMyTurn] = useState<boolean | null>(null);
    const [displayYourTurn, setDisplayYourTurn] = useState<boolean>(false);
    const [displayEvaded, setDisplayEvaded] = useState<boolean>(false);

    // Gameplay: Sound
    const [sound, setSoundSrc] = useState<{ src: string | undefined, timestamp: number }>({ src: '', timestamp: 0 });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { volume_SFX, volume_Music } = useVolume();

    // Gameplay: Game Over
    const [gameOver, setGameOver] = useState<boolean>(false);
    const [displayLose, setDisplayLose] = useState<boolean>(false);
    const [displayWin, setDisplayWin] = useState<boolean>(false);
    const [displayTie, setDisplayTie] = useState<boolean>(false);

    // Gameplay: Rematches
    const [ awaitingRematchResponse, setAwaitingRematchResponse ] = useState<boolean>(false)
    const [ rematchResponseTimeout, setRematchResponseTimeout ] = useState<boolean>(false)
    const [ rematchRequested, setRematchRequested ] = useState<boolean>(false)
    const [ rematchDeclined, setRematchDeclined ] = useState<boolean>(false);

    // Gameplay: Battle Room State
    const [battleRoomState, setBattleRoomState] = useState<BattleRoomState | null>(null);

    // Gameplay: Game Summary Message
    const [gameSummary, setGameSummary] = useState<GameSummary | undefined>();

    // Display "Your Turn" Message on Screen
    const displayYourTurnMessage = () => {
        setDisplayEvaded(false)
        setDisplayYourTurn(true)
        setTimeout(() => {
            setDisplayYourTurn(false)
        }, 1500);
    }

    // Display "Evaded" Message on Screen
    const displayEvadedMessage = () => {
        // Set opacity to '.ability-played-container' to 0.25
        document.querySelector('.ability-played-container')?.classList.add('o-0')
        setDisplayEvaded(true)
        setTimeout(() => {
            setDisplayEvaded(false)
            document.querySelector('.ability-played-container')?.classList.remove('o-0')
        }, 2000);
    }

    /**
     * Update Card State
     */
    const updateCardState = useCallback((battleRoomState: BattleRoomState) => {
        battleRoomState.players.forEach((player: any, key: string) => {
            const tokens: TokenMetadata[] = [];
            if (player.wallet === user.wallet) {
                player.tokens.forEach((token: TokenMetadata) => {
                    tokens.push(token)
                })
                setMyCards(tokens)
            }
            if (player.wallet !== user.wallet) {
                player.tokens.forEach((token: TokenMetadata) => {
                    tokens.push(token)
                })
                setOpponentCards(tokens)
            }
        });
    }, [user.wallet])

    /**
     * Initial Mounting 
     */
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [])

    /**
     * Wallet Connected Listener
     */
    useEffect(() => {
        if (user.wallet) {
            connectToGameServer(user.wallet).then(setSocket);
            storage.get('players').then((players: string) => {
                if (players) {
                const played: string[] = JSON.parse(players)
                    if (played.length) {
                        setPlayed(played);
                    }
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.wallet]);

    /**
     * Fetch Rooms Interval
     */
    useEffect(() => {

        // If we have not joined the lobby, join / create it
        if (router?.routeInfo.pathname === '/play' && user.socket && !lobby) {
            user.socket.joinOrCreate('lobby', {
                wallet: user.wallet
            }).then(setLobby);
        }
        // If we are already in the lobby, fetch rooms
        if (router?.routeInfo.pathname === '/play' && user.socket && lobby) {
            lobby.send('wayfarer');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.socket, router?.routeInfo.pathname, lobby]);

    /**
     * Lobby Activity Listener
     */
    useEffect(() => {
        if (lobby) {
            lobby.onMessage('wayfarer', (data) => {
                fetchRooms();
            });
            lobby.onMessage('room_created', (data) => {
                fetchRooms();
            });
            lobby.onMessage('room_left', (data) => {
                fetchRooms();
            });
            lobby.onMessage('room_joined', (data) => {
                fetchRooms();
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lobby])

    /**
     * Room Activity Listener
     */
    useEffect(() => {
        if (room) {

            // console.log('room', room)

            // Remove all listeners to avoid duplicates
            room.removeAllListeners();

            // Battle room state listener
            room.onStateChange((state: BattleRoomState) => {
                setBattleRoomState(state)

                // If we have two players, they are ready, and we have chosen tokens
                if (user.socket)  {

                    // Find player with matching wallet
                    let player: Player | undefined;
                    state.players.forEach((p: Player, key: string) => {
                        if (p.wallet === user.wallet) {
                            player = p;
                            setPlayer(p);
                        } else {
                            setOpponent(p);
                        }
                    });

                    if (player) {

                        // console.log('player', player)
                        // console.log('singlePlayer', state.singlePlayer)

                        if (!state.singlePlayer) {

                            // If we have two players, this player is not ready, and we have not chosen tokens
                            if ((state?.players.size === 2) && !player?.ready && !chooseTokens && !myCards.length) {
                                // Show token select modal
                                setChooseTokens(true)
                                // Move to battle ground
                                router?.push(`/play/battle`)
                            }
        
                            // If we have two player, this player is ready, and we have chosen tokens
                            if ((state.players.size === 2) && player?.ready && chooseTokens && myCards.length) {
                                setChooseTokens(false)
                            }
                            
                        }

                        if (state.singlePlayer) {
                            
                            // If we have two players, this player is not ready, and we have not chosen tokens
                            if ((state?.players.size === 2) && !player?.ready && !chooseTokens && !myCards.length) {
                                // Show token select modal
                                setChooseTokens(true)
                                // Move to battle ground
                                router?.push(`/play/battle`)
                            }

                            // If we have two player, this player is ready, and we have chosen tokens
                            if ((state.players.size === 2) && player?.ready && chooseTokens && myCards.length) {
                                setChooseTokens(false)
                            }

                        }                       


                    }

                    updateCardState(state)

                }

            });

            // When a player has their tokens and have signaled they are ready
            room.onMessage('playerReady', (data) => {
                const decompressed = decompressData(data)
                const msg: { sessionID: string, player: Player } = JSON.parse(decompressed)

                // If the player is this user
                if ((msg.player.wallet === user.wallet) && msg.player.tokens.length) {
                    setChooseTokens(false)
                }

                // If the player is not this user, and they have tokens
                if (msg.player.wallet !== user.wallet && msg.player.tokens.length) {
                    setOtherWalletAddress(msg.player.wallet)
                    setOtherPlayerConnected(msg.player.wallet)
                    setOtherPlayerUnavailable(false)
                    setOtherPlayerLeft(false)
                }
                lobby?.send('wayfarer');
            })

            // Both players are ready and we can start the game
            room.onMessage('gameStart', (data) => {
                const decompressed = decompressData(data);
                const msg: { randomPlayerWallet: string, randomBackdrop: string } = JSON.parse(decompressed);
                console.log('gameStart', msg);
                setRandomBackdrop(msg.randomBackdrop as BackdropTypes)
                setGameStart(msg)
                setChooseTokens(false)
                // console.log('gameStart', user.wallet, msg.randomPlayerWallet)
                setTimeout(() => {
                    if (user.wallet === msg.randomPlayerWallet) {
                        setMyTurn(true)
                        displayYourTurnMessage()
                    } else {
                        setMyTurn(false)
                    }
                }, 100)
                lobby?.send('wayfarer');

                // Start playing music
                if (!audioPlaying && musicPlayer && musicPlayer.audio.current) {
                    musicPlayer.audio.current.play();
                }

            });

            // Timer Update
            room.onMessage('timerUpdate', (data) => {
                const decompressed = decompressData(data)
                const msg: { turnCount: number, turnMax: number, timerValue: number } = JSON.parse(decompressed)
                let milliseconds = msg.timerValue;
                let seconds = Math.floor(milliseconds / 1000);
                setTimerValue(seconds)
                setTurnCount(msg.turnCount)
                setTurnMax(msg.turnMax)
            });

            // Timer Expire
            room.onMessage('timerExpire', (data) => {
                // console.log('timerExpire', data)
            });

            // Current Turn
            room.onMessage('currentTurn', (data) => {
                const decompressed = decompressData(data);
                const msg: { playerWallet: string } = JSON.parse(decompressed);
                setMyCard(null)
                setAbilitySelected(null)
                if (user.wallet === msg.playerWallet) {
                    setMyTurn(true)
                    displayYourTurnMessage()
                } else {
                    setMyTurn(false)
                }
            });

            // Card hover
            room.onMessage('hover', (data) => {
                const decompressed = decompressData(data);
                const msg = JSON.parse(decompressed);
                if (msg.wallet !== user.wallet) {
                    setOpponentCardHover(msg.token)
                }
            });

            // Ability
            room.onMessage('ability', (data) => {
                const decompressed = decompressData(data);
                const msg: { 
                    wallet: string; 
                    ability: Ability; 
                    source: TokenMetadata, 
                    target: TokenMetadata | TokenMetadata[]
                  } = JSON.parse(decompressed);

                // Prepare variable to store ownership boolean
                let owned: boolean = checkOwnership(msg.target, user);

                console.log('wallet', msg.wallet)
                console.log('owned', owned)
                console.log('ability', msg.ability)

                /**
                 * Outgoing Ability
                 */
                if ((msg.wallet === user.wallet) && !owned) {

                    // Push the ability to the my ability array
                    setMyAbilities([...myAbilities, msg.ability])

                    /**
                     * Group Ability
                     */
                    if (msg.ability.group && Array.isArray(msg.target)) {

                        const arrayTarget: TokenMetadata[] = msg.target as TokenMetadata[];
                        battleFX(msg.ability, msg.source, arrayTarget, 'cardAbility');

                    /**
                     * Single Ability
                     */
                    } else {
                        const singleTarget: TokenMetadata = msg.target as TokenMetadata;
                        battleFX(msg.ability, msg.source, singleTarget, 'cardAbility');
                    }

                }

                /**
                 * Ability for My Card
                 */
                if ((msg.wallet === user.wallet) && owned) {
                    setMyAbilities([...myAbilities, msg.ability])
                    const singleTarget: TokenMetadata = msg.target as TokenMetadata;
                    battleFX(msg.ability, msg.source, singleTarget, 'cardAbility');
                }

                /**
                 * Ability for Opponent Card
                 */
                if ((msg.wallet === otherPlayerConnected) && !owned) {
                    setOpponentAbilities([...opponentAbilities, msg.ability])
                    const singleTarget: TokenMetadata = msg.target as TokenMetadata;
                    battleFX(msg.ability, msg.source, singleTarget, 'incoming');
                }

                /**
                 * Incoming Ability
                 */
                if ((msg.wallet === otherPlayerConnected) && owned) {

                    setOpponentAbilities([...opponentAbilities, msg.ability])

                    /**
                     * Group Ability
                     */
                    if (msg.ability.group && Array.isArray(msg.target)) {

                        const arrayTarget: TokenMetadata[] = msg.target as TokenMetadata[];
                        battleFX(msg.ability, msg.source, arrayTarget, 'incoming');

                    /**
                     * Single Ability
                     */
                    } else {

                        const singleTarget: TokenMetadata = msg.target as TokenMetadata;
                        battleFX(msg.ability, msg.source, singleTarget, 'incoming');
                    }
                }

                clearAbilityAndCardSelected()

            });

            // Ability Evaded
            room.onMessage('evade', (data) => {
                const decompressed = decompressData(data);
                const msg: { 
                    token: TokenMetadata
                  } = JSON.parse(decompressed);
                console.log('evade', msg.token)
                evasiveFX(msg.token);
                displayEvadedMessage()
            });

            // Energy Update
            room.onMessage('energyUpdate', (data) => {
                const decompressed = decompressData(data);
                const msg = JSON.parse(decompressed);
                if (msg.wallet !== user.wallet) {
                    setOpponentEnergy(msg.energy)
                }
                if (msg.wallet === user.wallet) {
                    setMyEnergy(msg.energy)
                }                
            });

            // Game over event
            room.onMessage('gameOver', (data) => {
                const decompressed = decompressData(data);
                const msg: GameSummary = JSON.parse(decompressed);
                console.log('gameOver', msg)
                setGameSummary(msg)
                setMyEnergy(0);
                setOpponentEnergy(0);
                if (msg.winner === user.wallet) {
                    setDisplayWin(true)
                }
                if (msg.loser === user.wallet) {
                    setDisplayLose(true)
                }
                if (msg.winner === 'tie' && msg.loser === 'tie') {
                    setDisplayTie(true );
                }

                setGameOver(true)
                lobby?.send('wayfarer');

                playerStats();
                balanceCheck(testnet);

            });

            // Rematch request
            room.onMessage('rematchRequest', (data) => {
                const decompressed = decompressData(data);
                const msg = JSON.parse(decompressed);
                if (msg.wallet !== user.wallet) {
                    setRematchRequested(true)
                }
            });

            // Rematch accepted
            room.onMessage('rematchAccepted', (state: BattleRoomState) => {
                
                setRandomBackdrop(undefined);
                setGameStart(null);
                setMyTokens([]);
                setMyCards([]);
                setMyAbilities([]);
                setMyCard(null);
                setMyEnergy(0);
                setOpponentCard(null);
                setOpponentAbilities([]);
                setAbilitySelected(null);
                setPlayed([]);
                setCardAbilityPlayed(null);
                setDisplayAbilityPlayed(false);
                setDisplayLose(false);
                setDisplayWin(false);
                setDisplayTie(false);
                setOtherPlayerLeft(false);
                setOpponentEnergy(0);
                setOpponentCardHover(null);
                setOpponentCards([]);
                setTimerValue(0);
                setTurnCount(0);
                setTurnMax(0);
                setGameOver(false);
                setChooseTokens(true);
                setMyTurn(false);
                setSoundSrc({ src: '', timestamp: 0 });
                setAwaitingRematchResponse(false);
                setRematchResponseTimeout(false);
                setRematchRequested(false);
                setBattleRoomState(state);
                setGameSummary(undefined);
                setOtherPlayerConnected(null);
                lobby?.send('wayfarer');

            });

            // Rematch declined
            room.onMessage('rematchDeclined', (data) => {
                const decompressed = decompressData(data);
                const msg = JSON.parse(decompressed);
                if (msg.wallet !== user.wallet) {
                    setAwaitingRematchResponse(false)
                    setRematchDeclined(true)
                    // Leave Room
                    leave(user, room);
                }
                lobby?.send('wayfarer');
            });

            // Other player has left
            room.onMessage('playerLeft', (data) => {
                const decompressed = decompressData(data);
                const msg = JSON.parse(decompressed);
                if (msg.wallet !== user.wallet && !rematchDeclined) {
                    setOtherPlayerLeft(true)
                    // Leave Room
                    leave(user, room);
                }
                lobby?.send('wayfarer');
            });

        } else {
            // console.log('room not set')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        user,
        user.wallet,
        lobby,
        room, 
        router?.routeInfo.pathname,
        chooseTokens, 
        myCards, 
        opponentCards, 
        otherPlayerConnected, 
        rematchDeclined,
        myAbilities,
        opponentAbilities,
        audioPlaying,
        musicPlayer
    ]);

    /**
     * Gameplay: Is this token ready
     * @returns Boolean
     */
    const isReady = (token?: TokenMetadata | null | undefined) => {
        if (!token) {
            return false;
        }
        const result = myTokens?.filter(m => 
            (m?.token_id === token?.token_id) && (m.collection === token.collection)
        ).length ? true : false;
        return result;
    };

    /**
     * Get Ready: Is Equipment Attached to Any Team Member
     * @param equipment Equipment
     * @returns Boolean
     */
    const isEquipmentAttachedToAnyTeamMember = (equipment: ShopItemRow) => {
        return myTokens.some(token => token.frame?.id === equipment.id || token.wearable?.id === equipment.id);
    }

    /**
     * Get Ready: Check if this equipment is attached to this token
     * @param token 
     * @param equipment 
     * @returns 
     */
    const isThisEquipmentAttachedToThisToken = (token: TokenMetadata, equipment: ShopItemRow) => {
        return token.frame?.id === equipment.id || token.wearable?.id === equipment.id;
    }

    /**
     * Previously Played Wallets Listener
     */
    useEffect(() => {
        if (room) {

            // console.log('room', room)

            // If I have joined a room, refer to this room in the room list, check if I have played this wallet before
            const notPreviouslyPlayed = rooms.filter(r => r.roomId === room.id && !played.includes(r.metadata.wallet));
            if (notPreviouslyPlayed.length) {
                const playedData = [...played, notPreviouslyPlayed[0]?.metadata.wallet];
                setPlayed(playedData)
                storage.set('players', JSON.stringify(playedData)).then(() => {
                    console.log('Players storage updated', played)
                });    
            } else {
                // console.log('We have played this wallet before')
            }
        }
    }, [room, rooms, played])

    /**
     * Get Ready: Toggle Frame
     */
    const equipmentToggle = () => {
        const frameMetadata = frame?.metadata as ShopItemMetadata;
        const wearableMetadata = wearable?.metadata as ShopItemMetadata;
        const stats = new Stats();
        stats.defense = cardDefaultDefense + (frameMetadata?.defense_boost ? frameMetadata?.defense_boost : 0) + (wearableMetadata?.defense_boost ? wearableMetadata?.defense_boost : 0);
        stats.health = cardDefaultHealth + (frameMetadata?.health_boost ? frameMetadata?.health_boost : 0) + (wearableMetadata?.health_boost ? wearableMetadata?.health_boost : 0);
        const token = {
            ...prepareToken,
            frame: frame && frame?.id ? frame : new ShopItem(),
            wearable: wearable && wearable?.id ? wearable : new ShopItem(),
            stats: stats
        }
        updateToken(token as TokenMetadata)
    }

    /**
     * Get Ready: Frame Listener
     */
    useEffect(() => {
        equipmentToggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frame, wearable])

    /**
     * Get Ready: Toggle Token Selection
     */
    const toggleTokenSelect = () => {

        if (prepareToken) {

            if (!isReady(prepareToken)) {
                const selected = myTokens ? [...myTokens, prepareToken] : [prepareToken];
                setMyTokens(selected as TokenMetadata[]);
            } else {
                const selected = myTokens?.filter(mt => mt.token_id !== prepareToken.token_id);
                setMyTokens(selected);
            }

            // Clear the prepare token, frame, and wearable
            setPrepareToken(null);
            setFrame(null);
            setWearable(null);
            
        }
    };

    /**
     * Get Ready: Update Token Frame and Wearable
     */
    const updateToken = (t: TokenMetadata) => {
        const token = {
            ...t,
            frame: frame && frame.id ? frame : new ShopItem(),
            wearable: wearable && wearable.id ? wearable : new ShopItem(),
        };

        // Replace the matching token in the myTokens array
        const updatedTokens = myTokens.map(mt => mt.token_id === t.token_id ? token : mt);

        setMyTokens(updatedTokens as TokenMetadata[]);
        setPrepareToken(token as TokenMetadata);
    };

    useEffect(() => {
        // console.log('get-ready prepareToken', prepareToken)
        // console.log('get-ready frame', frame)
        // console.log('get-ready wearable', wearable)
        // console.log('get-ready myTokens', myTokens)
    }, [myTokens, prepareToken, frame, wearable]);

    /**
     * Loading Token and Prepare Token Listener
     */ 
    useEffect(() => {

        // Loading and no token yet, clear frame and wearable
        if (loadingToken && !prepareToken) {
            setFrame(null)
            setWearable(null)
        }

        // Not loading and prepare token exists
        if (!loadingToken && prepareToken) {

            // Check if the prepared token has a frame
            if (prepareToken.frame?.id && !frame) {
                // setFrame(prepareToken.frame)
            }

            // Check if the prepared token has a wearable
            if (prepareToken.wearable?.id && !wearable) {
                // setWearable(prepareToken.wearable)
            }

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingToken, prepareToken])

    /**
     * Fetch Rooms and Check if User has Created a Room
     */
    const fetchRooms = () => getRooms(user).then((rooms) => {
        const roomsCreated = rooms.filter(r => r.metadata.wallet === user.wallet)
        if (roomsCreated.length) {
            setRoomCreated(true)
        } else {
            setRoomCreated(false)
        }
        setRooms(rooms)
    });

    /**
     * On Room Update
     * @param rooms Rooms available
     * @param add Added room
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onRoomUpdate = (rooms: RoomAvailable[], add: RoomAvailable) => {
        console.log('Updated rooms:', rooms);
        console.log('Room added:', add);
        // Update your state based on the new room list
        setRooms(rooms);
    }

    /**
     * Opponent Card Click Event
     * @param card Card Clicked
     */
    const opponentClick = (card: TokenMetadata) => {
        if (user.socket && myCard && abilitySelected && room) {
            if (abilitySelected.self) {
                return;
            } else {
                let target: TokenMetadata | TokenMetadata[];
                if (abilitySelected.group) {
                    target = opponentCards as TokenMetadata[];
                } else {
                    target = card as TokenMetadata;
                }
                ability(user, room, abilitySelected, myCard, target)
            }
        }
    };

    /**
     * Click My Own Card
     * @param card Card Clicked
     */
    const selfClick = (card: TokenMetadata) => {
        if (user.socket && myCard && abilitySelected && room) {
            if (!abilitySelected.self) {
                return;
            } else {
                let target: TokenMetadata | TokenMetadata[];
                if (abilitySelected.group) {
                    target = myCards as TokenMetadata[];
                } else {
                    target = card as TokenMetadata;
                }
                ability(user, room, abilitySelected, myCard, target)
            }
        }
    }

    /**
     * Gameplay: Select Card Ability and Emit Card Selection
     * @param user User object
     * @param ability Ability
     * @param card Card's metadata
     */
    const selectAbility = (ability: Ability, card: TokenMetadata) => {
        if (room) {
            setAbilitySelected(ability)
            click(user, room, ability, card)
        }
    }

    /**
     * Gameplay: Clear Player's Selected Ability and Card
     * @param ability 
     * @param card 
     */
    const clearAbilityAndCardSelected = () => {
        setMyCard(null)
        setAbilitySelected(null)
        setOpponentCard(null)
    }

    /**
     * Gameplay: Card Ability Name Fly-In
     * @param ability Ability object used by player
     */
    const displayCardAbilityName = (ability: Ability) => {
        if (ability) {
            setCardAbilityPlayed(ability)
            setDisplayAbilityPlayed(true)
            setTimeout(() => {
                setDisplayAbilityPlayed(false)
            }, 1000)
        }
    }

    /**
     * Gameplay: Run Battle FX Animation
     * @param ability Ability name
     * @param source Source of ability
     * @param target Target for ability
     * @param context Context of ability 'cardAbility' or 'incoming'
     */
    const battleFX = (
        ability: Ability,
        source: TokenMetadata,
        target: TokenMetadata | TokenMetadata[],
        context: AbilityMode
    ) => {

        const abilitySent = ability;
        const isolatedAbility = abilities.find(a => a.name === abilitySent.name);

        // Call method in isolated ability
        if (isolatedAbility && isolatedAbility.method) {
            displayCardAbilityName(abilitySent)
            isolatedAbility.method(source, target, context);
            if (isolatedAbility.sound?.url) {
                setSoundSrc({ src: isolatedAbility.sound?.url, timestamp: Date.now() })
            }
        }
    }

    /**
     * Gameplay: Battle FX Evasive FX
     */
    const evasiveFX = (target: TokenMetadata) => {
        evade(target).then(() => {
            console.log('Evasive FX complete')
        });
    }

    /**
     * Gameplay: Reset Game State
     */
    const resetGame = (chooseTokens: boolean = true) => {

        // Clear your deck
        setMyCards([]);
        setMyAbilities([]);

        // Clear opponent information
        setOpponentCards([]);
        setOpponentAbilities([]);
        setOtherPlayerConnected(null);
        setChooseTokens(chooseTokens);
        setOtherPlayerLeft(false);
        setDisplayWin(false);
        setDisplayLose(false);

        // Clear played
        setPlayed([]);

        // Clear energy
        setMyEnergy(0);
        setOpponentEnergy(0);

        // Clear turn
        setMyTurn(null);

        // Clear ability
        setAbilitySelected(null);

        // Clear card
        setMyCard(null);

        // Clear backdrop
        setRandomBackdrop(undefined);

        // Clear ability played
        setCardAbilityPlayed(null);

        // Clear ability played display
        setDisplayAbilityPlayed(false);

        // Clear game start
        setGameStart(null);

        // Clear game over
        setGameOver(false);
        setDisplayLose(false);
        setDisplayWin(false);
        setDisplayTie(false);
        setGameSummary(undefined);

    }

    return (
        <PlayContext.Provider
            value={{

                // State
                lobby,
                rooms,
                room,
                roomCreated,
                battleRoomState,
                requiredTokens,
                gameStart,

                // Player
                player,
                loadingToken,
                prepareToken,
                frame,
                wearable,
                myTokens,
                myCards,
                myCard,
                myAbilities,
                myEnergy,

                // Opponent
                opponent,
                opponentCard,
                opponentAbilities,

                // Abilities
                abilitySelected,
                played,
                cardAbilityPlayed,
                displayAbilityPlayed,

                // Gameplay
                displayYourTurn,
                displayEvaded,
                displayLose,
                displayWin,
                displayTie,
                otherWalletAddress,
                otherPlayerUnavailable,
                otherPlayerConnected,
                otherPlayerLeft,
                opponentEnergy,
                opponentCardHover,
                opponentCards,
                chooseTokens,
                randomBackdrop,
                myTurn,
                timerValue,
                turnCount,
                turnMax,
                gameOver,
                gameSummary,

                // Methods
                fetchRooms,
                setRoom,
                setOtherWalletAddress,
                setServerUnavailable,
                setAwaitingRematchResponse,
                setDisplayYourTurn,
                resetGame,
                setGameSummary,
                setLoadingToken,
                setPrepareToken,
                setFrame,
                setWearable,
                toggleTokenSelect,
                updateToken,
                isReady,
                isEquipmentAttachedToAnyTeamMember,
                isThisEquipmentAttachedToThisToken,
                setMyCard,
                selectAbility,
                opponentClick,
                selfClick,
            }}
        >

            {children}
    
            {chooseTokens ? (
                <GetReady />
            ) : null}

            {/* Sound FX */}
            {sound.src && <Sound key={sound.timestamp} src={sound.src} volume={volume_SFX} />}

            {/* If the server is unavailable */}
            <IonAlert
                mode="ios"
                isOpen={serverUnavailable}
                onDidDismiss={() => setServerUnavailable(false)}
                title={`Server unavailable`}
                message={`The game server is currently unavailable.`}
                buttons={[
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: (value: any) => {
                    console.log('Server unavailable message dismissed')
                    }
                }
                ]}
            />

            {/* If the other player isn't available */}
            <IonAlert
                mode="ios"
                isOpen={otherPlayerUnavailable}
                onDidDismiss={() => setOtherPlayerUnavailable(false)}
                title={`Player unavailable`}
                message={`The player you're trying to join is not available.`}
                buttons={[
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: (value: any) => {
                    console.log('Player unavailable message dismissed')
                    }
                }
                ]}
            />

            {/* If the other player has left the game */}
            <IonAlert
                mode="ios"
                isOpen={otherPlayerLeft}
                backdropDismiss={false}
                onDidDismiss={() => setOtherPlayerLeft(false)}
                title={`Player has left`}
                message={`The other player has left the game.`}
                buttons={[
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: (value: any) => {
                        if ( room ) {
                            resetGame(false)
                            router?.push('/play')                            
                        }
                    }
                }
                ]}
            />

            {/* Waiting for Rematch Response */}
            <IonLoading 
                mode="ios"
                isOpen={awaitingRematchResponse} 
                message="Awaiting rematch response..." 
                duration={60 * 1000} onDidDismiss={() => {
                if (awaitingRematchResponse) {
                    setRematchResponseTimeout(true)
                }
            }} />

            {/* Rematch Timeout */}
            <IonAlert
                mode="ios"
                onDidPresent={() => {
                    setAwaitingRematchResponse(false)
                }}
                isOpen={rematchResponseTimeout}
                header="Rematch Request Timed Out"
                message="It appears the other player did not respond to your rematch request."
                buttons={[{
                    text: 'Dismiss',
                    handler: () => {
                        setRematchResponseTimeout(false)
                        resetGame(false)
                        router?.push('/play')  
                    }
                }]}
            />

            {/* Rematch Declined */}
            <IonAlert
                mode="ios"
                onDidPresent={() => {
                    setAwaitingRematchResponse(false)
                }}
                isOpen={rematchDeclined}
                header="Rematch Declined"
                message="The other player has declined your rematch request."
                buttons={[{
                    text: 'Dismiss',
                    handler: () => {
                        resetGame(false)
                        router?.push('/play')  
                    }
                }]}
            />

            {/* Rematch Request */}
            <IonAlert
                mode="ios"
                isOpen={rematchRequested}
                header="Would you like to play again?"
                buttons={[
                    {
                        text: 'Nope',
                        role: 'cancel',
                        handler: () => {
                            console.log('Alert canceled');
                            if (room) {
                                rematchDecline(user, room).then(() => {
                                    leave(user, room).then(() => {
                                        resetGame(false)
                                        router?.push('/play')  
                                    });
                                });
                            }  
                        },
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            if (room) {
                                rematchAccepted(user, room);
                            }                            
                        },
                    },
                ]}
                onDidDismiss={({ detail }) => console.log(`Dismissed with role: ${detail.role}`)}
            ></IonAlert>

            {tokensLoaded && collectiblesLoaded === 100 && user.wallet ? (
                <>
                <Header />
                {!router?.routeInfo.pathname.startsWith('/play/') ? (
                    <BottomNav />
                ) : null}
                </>
            ) : null}


        </PlayContext.Provider>
    );

};

export default PlayContextProvider;