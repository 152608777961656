// Env
import { envmode } from './Env';

// Config
import { getNetworkId } from './config/utils';
import { defaultUserState } from './config/config';
import { CollectionDetails, Network } from './config/collections.interface';
import { TestTokenObject } from './config/shop';

// React
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

// Ionic
import { Storage } from '@ionic/storage';
import { IonAlert, useIonAlert, useIonRouter } from '@ionic/react';

// Interfaces
import { UserState } from './interfaces/User.interface';
import { ERC20tokenABI, UniswapERC20Token } from './interfaces/Uniswap.Interface';
import { TokenResult } from './interfaces/Etherscan.interface';
import { AbilityLib } from './interfaces/Ability.interface';

// Colyseus
import { TokenMetadata } from './generated/TokenMetadata';

// Services
import { getUniswapTokens } from './services/Uniswap';
import { getCollectibleCount, fetchTokenMetadata, promiseDelay, getAccountsAndSign } from './services/Wallet';

// Components
import { cardDefaultHealth } from './components/play/PlayerCard';

// Web3
import Web3, { infuraHTTPProvider, Web3Provider, Web3Providers } from './web3/web3';
import { ethers } from 'ethers';

// Communications
import * as Colyseus from "colyseus.js";

// Types
import { GameModeTypesInterface } from './types/GameModes.type';

// Schema
import { ShopItem } from './generated/ShopItem';
import { Stats } from './generated/Stats';

// Components
import WaitModal from './components/ui/WaitModal';
import H5AudioPlayer from 'react-h5-audio-player';
import WalletInfo from './components/ui/WalletInfo';
import ColosseumBackdrop from './components/ui/ColosseumBackdrop';

// Supabase
import { createOrGetPlayer, getPlayerByWallet, signInAnonymouslyWithWallet, signOutUser, userLoggedIn } from './supabase/auth';
import { getInGameCurrencyBalance, getPurchases, supabase } from './supabase/api';
import { ShopItemRow } from './supabase/types';

// Config
import { CollectionSlug, initAbilities, initCollections } from './config/collections';
import { Session } from '@supabase/supabase-js';


// Prepare local storage
const storage = new Storage();
storage.create();

/**
 * Log user out
 */
const logoutUser = async () => {
    await storage.clear().then( async () => {
        await signOutUser()
        window.location.reload();
    });    
}

/**
 * Global Context Provider
 */
export const UserContext = React.createContext(defaultUserState);

const UserContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    // History
    const router = useIonRouter()

    // User
    const [userState, setUserState] = useState<UserState>(defaultUserState)

    // Access
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [connectingWallet, setConnectingWallet] = useState<boolean>(false)

    // Collections
    const [collections, setCollections] = useState<CollectionDetails[]>([])

    // Abilities
    const [abilities, setAbilities] = useState<AbilityLib[]>([])

    // Game Mode
    const [mode, setMode] = useState<GameModeTypesInterface | null>(null)

    // Main
    const [settings, setSettings] = useState<boolean>(false);
    const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
    const [musicPlayer, setMusicPlayer] = useState<H5AudioPlayer | null>(null);
    const [walletInfo, setWalletInfo] = useState<boolean>(false);

    // Shop
    const [zeroXModal, setZeroXModal] = useState<boolean>(false);
    const [shopItems, setShopItems] = useState<ShopItemRow[]>([]);
    const [waitModal, setWaitModal] = useState<boolean>(false);
    
    // Collectibles
    const [tokensLoaded, setTokensLoaded] = useState<boolean>(false)
    const [displayCollectiblesLoaded, setDisplayCollectiblesLoaded] = useState<boolean>(false)
    const [collectibleCount, setCollectibleCount] = useState<number>(0);
    const [collectiblesLoaded, setCollectiblesLoaded] = useState<number>(0);

    // Alert Modal
    const [alertModal, setAlertModal] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertHeader, setAlertHeader] = useState<string>('');

    // Tooltip
    const [tooltip, setTooltip] = useState<{ type: any, data: any }>({ type: null, data: null });
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    // Web3 Provider
    const [provider, setProvider] = useState<Web3Provider | null>(null);

    // Network
    const [networkMessage, setNetworkMessage] = useState<string>('');
    const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);
    const [testnet, setTestnet] = useState<boolean>(false);

    // Theme
    const [chooseTheme, setChooseTheme] = useState<boolean>(false);
    const [theme, setTheme] = useState<CollectionSlug | null>(null);
    const [themeChangeOverlay, setThemeChangeOverlay] = useState<boolean>(false);

    const [presentAlert] = useIonAlert();

    // Version
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [newVersion, setNewAppVersion] = useState<boolean>(false)

    /**
     * Check Version of App
     */
    // const checkAppVersion = () => {
    //     try {
    //         const domain = `${process.env.REACT_APP_GLADIATOR_DOMAIN}`.replaceAll('https://', '').replaceAll('http://', '');
    //         externalRequest(`//${domain}`).get(`${app()}latest-tag.json?u=${moment().unix()}`, { timeout: 10000 })
    //         .then(async (res: AxiosResponse) => {
    //             const current = git?.default?.tag;
    //             const response = res.data.tag;
    //             console.log('Client', current)
    //             console.log('Server', response)
    //             if ((current !== response) && (current < response) && !newVersion) {
    //                 setNewAppVersion(true)
    //             }
    //         }).catch((error: any) => {
    //             console.log('error', error);
    //         });          
    //     } catch (error) {
    //         console.log('error', error);
    //     }
    // }

    /**
     * Choose Web3 Provider
     * @param p Web3Provider
     * @param signature boolean
     * @param wallet string
     * @returns promise with boolean
     */
    const chooseProvider = async (p: Web3Provider, signature: boolean = true) => {
      
        // startLoading(`Connecting wallet and loading NFTS...`)
        setWaitModal(true)

        // Clone state
        let state = userState;

        // Begin Web3 Request and pass along the provider object
        const web3 = new Web3(p.provider)

        try {
            await web3.eth.requestAccounts().then( async () => {

                // Display collectibles loaded progress modal
                setDisplayCollectiblesLoaded(true)

                // Set Provider
                setProvider(p)

                // Get Chain ID
                const chain: number = await p.provider.request({ method: 'net_version' });
                const chainID = parseInt(chain.toString())

                // Update cloned user state with: provider, web3 instance, and chain ID
                state.user.provider = p;
                state.user.web3 = web3;
                state.user.chainID = chainID;

                // If we are in dev mode, we are checking for testnet
                if (envmode === 'dev') {

                    // If the chain ID is not sepolia, display an error using useIonAlert
                    if (chainID !== getNetworkId(Network.Sepolia)) {
                        setNetworkMessage('Please make sure you\'re connected to Sepolia Testnet');
                        setShowNetworkAlert(true)
                        return
                    }

                // If we are in production mode, we are checking for mainnet
                } else {

                    // If the chain ID is not ETH Mainnet, display an error using useIonAlert
                    if (chainID !== 1) {
                        setNetworkMessage('Please make sure you\'re connected to ETH Mainnet');
                        setShowNetworkAlert(true)
                        return
                    }

                }

                // Get Wallet Address and Sign Message (if signature is true)
                await getAccountsAndSign(state.user, signature).then( async (newUserState) => {                
                    if (newUserState.wallet) {
                        await signInAnonymouslyWithWallet(newUserState.wallet, p).then( async (player) => {
                            newUserState.player = player
                            startSequence();
                        })
                    } else {
                        console.error('No wallet found')
                    }
                }).catch((error: any) => {

                    setWaitModal(false)
                    if (error.code === 4001) {
                        setAlertHeader('Wallet Connection Error')
                        setAlertMessage('User rejected wallet connection request.')
                    } else {                
                        setAlertHeader('Wallet Connection Error')
                        setAlertMessage(`${error.message}`)
                    }
                    setAlertModal(true)

                })

            }).catch((error: any) => {
                console.log('error', error)
                setWaitModal(false)
                presentAlert({
                    mode: 'ios',
                    header: 'Wallet Connection Error',
                    message: 'Please check wallet connection settings to continue.',
                    buttons: ['OK']
                });
            });
        } catch (error: any) {
            setWaitModal(false)
            switch (error.code) {
            case 4001:
                console.log('User rejected wallet connection request.');
                break;
            }
        }

    }

    /**
     * Start Session
     * @param loggedIn Supabase Session
     */
    const startSession = async (loggedIn: Session) => {

        const emailUser = loggedIn?.user.app_metadata?.provider === 'email' ? true : false;
        let wallet: string | null = null;

        // If the user is not an email user, we need to connect their wallet
        if (!emailUser) {

            const provider = Web3Providers.find(p => p.name === loggedIn?.user.user_metadata?.provider)
            if (provider) {
                await chooseProvider(provider, false)
            }

        // If the user is an email user, we need to check if they have a wallet
        } else {

            if (loggedIn?.user?.user_metadata?.wallet) {
                wallet = loggedIn.user.user_metadata.wallet;
            } else {
                const newWallet = ethers.Wallet.createRandom();
                const { error } = await supabase.auth.updateUser({
                    data: {
                        wallet: newWallet.address,
                        pk: newWallet.privateKey,
                        mnemonic: newWallet.mnemonic
                    }
                });
                if (error) {
                    console.error('Error updating user metadata:', error);
                }
                wallet = newWallet.address;
            }

            userState.user.web3 = infuraHTTPProvider;
            userState.user.emailUser = emailUser;
            userState.user.email = loggedIn.user.email;
            userState.user.wallet = wallet;
            userState.user.player = await createOrGetPlayer(wallet!, loggedIn);
            setUserState({ ...userState });
            startSequence();

        }

    }

    /**
     * Start Sequence
     * @param wallet string
     */
    const startSequence = async () => {

        setWaitModal(true)
        setDisplayCollectiblesLoaded(true)

        // Get gameplay collections
        await initCollections().then(async (collectionArray) => {
        
            // Set collections
            setCollections(collectionArray)

            await initAbilities().then(async (abilitiesArray) => {

                // Set abilities
                setAbilities(abilitiesArray)
    
                // Get tokens and metadata for this user
                getCollectibleCount(userState.user, collectionArray).then(tokenResult => {

                    // Set collectible total count
                    setCollectibleCount(tokenResult.length)

                    // Get collectibles metadata
                    getCollectiblesMetadata(tokenResult).then(tokens => {

                        // Process collectibles and prepare for play
                        const preparedTokens: TokenMetadata[] = [];

                        // If the user doesn't own any tokens, set tokens loaded to 100%
                        if (tokenResult.length === 0) {

                            setCollectiblesLoaded(100)

                        // If the user owns tokens, prepare them for play
                        } else {
        
                            tokens.forEach((t) => {
                                const token = new TokenMetadata();
                                token.token_id = t.token_id;
                                token.name = t.name;
                                token.description = t.description;
                                token.image = t.image;
                                token.attributes = t.attributes;
                                token.flipped = t.flipped;
                                token.collection = t.collection;
                                token.stats = new Stats();
                                token.stats.defense = cardDefaultHealth;
                                token.stats.health = cardDefaultHealth;
                                token.owned = true;
                                preparedTokens.push(token);
                            });
        
                        }
                        
                        // console.log('preparedTokens', preparedTokens)
    
                        // Update user state with new collectibles
                        userState.user.collectibles = preparedTokens;
    
                        // Update state
                        setUserState({...userState});
    
                        // Wait a moment, close loading modal, and set tokens loaded to true to enter game
                        setTimeout(() => {
                            setTokensLoaded(true)
                            setWaitModal(false)
                            setDisplayCollectiblesLoaded(false)
                            getBalanceAndStats()
                        }, 1000)

                    })
    
                }).finally(() => {
                    console.log('Collectibles loaded')
                });

            });

        });

    }

    /**
     * Check if we are on the testnet
     */
    const checkTestnet = useCallback(async () => {
        // If it is demo mode, we are on the testnet
        if (!userState.user.provider) {
            setTestnet(true);
            return true;
        }
        const chain: number = await userState.user.provider?.provider.request({ method: 'net_version' });
        const chainID = parseInt(chain.toString())
        const testnet = chainID === getNetworkId(Network.Sepolia);
        setTestnet(testnet);
        return testnet;
    }, [userState.user.provider]);

    /**
     * Set Socket Connection for User
     * @param s Socket Connection
     */
    const setSocket = (s: Colyseus.Client) => {
        let state = userState;
        state.user.socket = s;
        setUserState({...state});
    }

    /**
     * Clear storage and log user out
     */
    const logout = async () => {
        logoutUser()
    };

    /**
     * Change Theme
     * @param theme CollectionSlug
     */
    const changeTheme = (theme: CollectionSlug | null) => {

        const transitionTime = 1.5 * 1000

        // Show wait modal
        setWaitModal(true)

        // Enable overlay
        setThemeChangeOverlay(true)

        setTimeout(() => {

            // Hide choose theme modal
            setChooseTheme(false)

            setTimeout(() => {

            // Fade in overlay
            document.getElementById('theme-change-overlay')?.classList.add('fade-in')

                // When animation completes, continue
                setTimeout(() => {

                    // Set theme class on app container
                    const appContainer = document.querySelector('#root > ion-app')
                    if (appContainer) {
                        // Remove any existing theme classes
                        appContainer.classList.forEach((c) => {
                            if (c.startsWith('theme-')) {
                                appContainer.classList.remove(c)
                            }
                        })
                        appContainer.classList.add(`theme-${theme}`)
                    }

                    // Set theme
                    setTheme(theme)
                    // Save theme to storage
                    // storage.set('theme', theme)

                    // Fade out overlay
                    document.getElementById('theme-change-overlay')?.classList.add('fade-out')

                    // When animation completes, disable wait modal and theme modal
                    setWaitModal(false)
                    setChooseTheme(false)
                    setTimeout(() => {
                        setThemeChangeOverlay(false)
                    }, transitionTime / 2)

                }, transitionTime)

            }, 10)

        }, 10)

    }

    /**
     * Set Game Mode
     * @param s Socket Connection
     */
    const setGameMode = (mode: GameModeTypesInterface) => {
        let state = userState;
        state.user.mode = mode;
        setUserState({...state});
        setMode(null)
        setMode(mode)
    }

    /**
     * Get Collectibles
     * @param user User object
     * @param collectibles TokenMetadata[]
     * @returns TokenMetadata[]
     */
    const getCollectiblesMetadata = async (tokens: TokenResult[]): Promise<TokenMetadata[]> => {

        // console.log('collectiblesLoaded' , collectiblesLoaded)

        let loaded = 0;
        setCollectiblesLoaded(loaded)
        const metadataPromises = tokens.map(async (t, i) => {
            await promiseDelay(1 * i);
            const tokenID = Number(t.TokenId);
            return fetchTokenMetadata(t.Collection, tokenID).then(async (metadata) => {
                loaded++;
                const percentage = (loaded / tokens.length) * 100;
                const formattedPercentage = Number(percentage.toFixed());

                // console.log('formattedPercentage', formattedPercentage)

                setCollectiblesLoaded(formattedPercentage);
                return metadata;
            });
        })
        const allMetadata = await Promise.all(metadataPromises);
        const collectibles = allMetadata.filter((m) => m !== undefined) as TokenMetadata[];
        return collectibles.length > 0 ? collectibles : [];

    }

    /**
     * Get Player Stats
     */
    const playerStats = useCallback(async () => {
        if (userState.user.wallet) {
            getPlayerByWallet(userState.user.wallet).then((player) => {
                if (player) {
                    let state = userState;
                    state.user.player = player;
                    setUserState({...state});
                }
            });
        }
    }, [userState]);

    /**
     * Balance Check
     */
    const balanceCheck = useCallback(async (testnet: boolean) => {

        try {
            
            // Check web3 provider
            if (!userState.user.web3) {
                console.warn('No web3 provider found')
                return
            }

            // console.log('balanceCheck')
    
            // Balance object that aligns with the User interface
            const balance = {
                ethBalance: 0,
                erc20Balance: 0,
                gladiiBalance: 0
            }
    
            // Get ERC20 Balance
            const preferredERC20 = testnet ? 'LINK' : 'USDC';
    
            let erc20Token: UniswapERC20Token = {} as UniswapERC20Token;
    
            if (testnet) {
                erc20Token = await TestTokenObject.tokens.find(token => token.symbol === preferredERC20)!;
            } else {
                await getUniswapTokens(userState.user).then(async (tokens: any) => {
                    erc20Token = tokens.find((token: UniswapERC20Token) => token.symbol === preferredERC20)!;
                });
            }
    
            const tokenContract = new userState.user.web3.eth.Contract(ERC20tokenABI, erc20Token?.address);
    
            // Get ERC20 Balance
            const erc20balance = await tokenContract.methods.balanceOf(userState.user.wallet).call();
            const formattedErc20Balance = erc20balance / Math.pow(10, erc20Token?.decimals!);
                    

            // Get ETH Balance
            const ethBalance = await userState.user.web3.eth.getBalance(userState.user.wallet);
            const formattedEthBalance = userState.user.web3.utils.fromWei(ethBalance);

            // Update balance object
            balance.ethBalance = Number(formattedEthBalance);
            balance.erc20Balance = Number(formattedErc20Balance);
    
            // Get In-Game Currency Balance
            if (userState.user.player) {
                const gladiiBalance = await getInGameCurrencyBalance(userState.user.player.uuid)
                balance.gladiiBalance = gladiiBalance?.total_currency ?? 0;
            }
            
            // Update user state with new balance object, but clone it first
            let state = userState;
            state.user.ethBalance = balance.ethBalance;
            state.user.erc20Balance = balance.erc20Balance;
            state.user.gladiiBalance = balance.gladiiBalance;
            setUserState({...state});

        } catch (error) {
            
            console.error('Error getting balance', error);

        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Get Balance and Stats
     */
    const getBalanceAndStats = useCallback(async () => {
        checkTestnet().then(async (testnet) => {

            // Get Balance
            balanceCheck(testnet)
    
            // Get Player Stats
            playerStats()
            
            // Get Purchased Items
            getShopItems()

        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [balanceCheck, playerStats, checkTestnet])

    /**
     * Get Shop Items
     */
    const getShopItems = () => {
        getPurchases(userState.user).then((purchases) => {
            const shopItems: ShopItemRow[] = [];
            purchases?.forEach((p) => {
                const item = new ShopItem();
                item.id = p.id;
                item.type = p.type ?? '';
                item.name = p.name ?? '';
                item.description = p.description ?? '';
                item.collection = p.collection ?? '';
                item.cost_usdc = p.cost_usdc ?? 0;
                item.cost_eth = p.cost_eth ?? 0;
                item.cost_gladii = p.cost_gladii ?? 0;
                item.metadata = p.metadata as any;
                shopItems.push(item as ShopItemRow);
            })
            setShopItems(shopItems as ShopItemRow[]);
        }).catch((error) => {    
            console.error('Error getting shop items', error);
        });
    }

    useEffect(() => {
        // console.log('Logged In', loggedIn)
        // console.log('User State', userState.user)
        // console.log('Collections', collections)
        // console.log('Abilities', abilities)
    }, [loggedIn, userState, collections, abilities])
    
    // No wallet? No access
    useEffect(() => {
        if (!userState.user.wallet && router?.routeInfo.pathname !== '/') {
            router?.push('/')
        }
    }, [router, userState.user.wallet, router?.routeInfo.pathname])

    /**
     * Check if the user is logged in
     */
    const checkAuth = useCallback(async () => {
        setTimeout(async () => {
            await userLoggedIn().then(async (loggedIn) => {
                if (loggedIn) {
                    startSession(loggedIn)
                }
            });
        }, 1100)

    }, [])

    useEffect(() => {
        checkAuth()
    }, [checkAuth])

    return (
        <UserContext.Provider
            value={{

                // Start Session
                startSession,

                // Logged In
                loggedIn,
                setLoggedIn,

                // Ion Router
                router,

                // User
                user: userState.user,

                // Wallet
                chooseProvider,
                connectingWallet,
                setConnectingWallet,
                tokensLoaded,
                walletInfo,
                setWalletInfo,   
                
                // Collections
                collections,
                setCollections,

                // Abilities
                abilities,
                setAbilities,

                // Collectibles
                displayCollectiblesLoaded,
                collectibleCount,
                setCollectibleCount,
                collectiblesLoaded,
                setCollectiblesLoaded,

                // Player Stats
                playerStats,

                // Theme
                chooseTheme,
                setChooseTheme,
                theme,
                changeTheme,

                // Game mode
                mode,
                setGameMode,

                // Settings
                settings,
                setSettings,

                // Audio
                audioPlaying,
                setAudioPlaying,
                musicPlayer,
                setMusicPlayer, 

                // Alert Modal
                alertModal,
                setAlertModal,
                alertMessage,
                setAlertMessage,
                alertHeader,
                setAlertHeader,

                // Socket for Game Server
                setSocket,

                // 0x
                zeroXModal,
                setZeroXModal,

                // Shop Items
                balanceCheck,
                shopItems,
                setShopItems,
                getShopItems,

                // Purchase Modal
                waitModal,
                setWaitModal,

                // Tooltip
                tooltip,
                setTooltip,
                showTooltip,
                setShowTooltip,

                // Testnet
                testnet,

                // Logout
                logout
            }}
        >
        <ColosseumBackdrop />
        {/* {isPlatform('desktop') && (
            <SnowOverlay maxParticles={25} disabledOnSingleCpuDevices />
        )} */}
        {children}

            {/* <IonToast
                position="bottom"
                mode='md'
                color={'primary'}
                isOpen={newVersion}
                message="Game Update! Please refresh your browser."
                buttons={[{
                    text: 'Refresh',
                    handler: () => { window.location.reload() }
                }]}
            /> */}

            <IonAlert
                mode='ios'
                color={'primary'}
                isOpen={showNetworkAlert}
                onDidDismiss={() => setShowNetworkAlert(false)}
                header={'Incorrect Network'}
                message={networkMessage}
                buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        setShowNetworkAlert(false)
                        setWaitModal(false)
                    }
                },
                {
                    text: 'Switch Network',
                    handler: () => {

                        setShowNetworkAlert(false)
                        
                        let chainId: string;
                        if (envmode === 'dev') {
                            chainId = `0x${getNetworkId(Network.Sepolia).toString(16)}`;
                        } else {
                            chainId = `0x1`;
                        }
                        
                        provider?.provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId }],
                        }).then(() => {
                            setWaitModal(false)
                            chooseProvider(provider)
                        }).catch((error: any) => {
                            setWaitModal(false)
                        });
                        
                        
                    }
                }
                ]}
            />

            {/* General Alert Modal */}
            <IonAlert
                mode='ios'
                color={'primary'}
                isOpen={alertModal}
                onDidDismiss={() => setAlertModal(false)}
                header={alertHeader}
                message={alertMessage}
                buttons={['OK']}
            />

            <WalletInfo />

            {themeChangeOverlay ? (
                <div id="theme-change-overlay" />
            ) : null}

            <WaitModal />

        </UserContext.Provider>
    );
};

export default UserContextProvider;
