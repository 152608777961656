// React
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import UserContextProvider from './Context';
import PlayContextProvider from './pages/PlayContext';
import { VolumeProvider } from './VolumeContext';

// Ionic
import { IonApp, IonRouterOutlet, IonTabBar, IonTabs, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Typeography */
import './../src/fonts/gladiator-arena/stylesheet.css';
import './../src/fonts/pt-serif/stylesheet.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/foundation.scss';
import './theme/tachyons.scss';
import './../node_modules/animate.css/animate.min.css';
import './theme/space-backdrop.scss';
import './theme/animations.scss';
import './theme/utility.scss';
import './theme/global.scss';
import './theme/colors.scss';
import './theme/utils.scss';
import './theme/checkmark.scss';
import './theme/settings.scss';
import './theme/sound-bars.scss';
import './theme/backdrop.scss';
import './theme/modal-connect-wallet.scss';
import './theme/modal-release-notes.scss';
import './theme/modal-choose-token.scss';
import './theme/modal-shop-item.scss';
import './theme/pages.scss';
import './theme/tables.scss';

/** Partners */
import './collections/bedlams/styles.scss'
import './collections/buns/styles.scss'

/** Screens */
import Home from './pages/Home';
import Play from './pages/Play';
import Lobby from './pages/Lobby';
import Leaderboard from './pages/Leaderboard';
import Collection from './pages/Collection';
import Shop from './pages/Shop';
import Collectible from './pages/Collectible';

/** Data */
import * as gitData from './data/latest-tag.json';
import Admin from './pages/Admin';

// Paypal
import { PayPalScriptProvider, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';

// Paypal Checkout
const paypalOptions: ReactPayPalScriptOptions = {
    clientId: process.env.NODE_ENV === 'production' ? `AUgi55hArziuzJmt9tBpUBMyWXjTDGSfZfv1MC-OIPW7OPpqD1GZd6MpGImOzek0tVp3mv-4ZMZCO9Fr` : `AQQ1uMqDo8TGVvWJ3cn_C8aWRuMbDA3w4vSHh3JQNyt7mVhprVKxLsfPD0tTRUSzaOZ33764ZiPJzuiT`,
    currency: "USD",
};

// Ionic React Setup
setupIonicReact({
  mode: 'md'
});

const App: React.FC = () => {

  const git: any = gitData;

  useEffect(() => {

    // Gladiator Helmet
    const asciiArt = `
                .:=::.                 
            -+*#%%%@+-==+++-.           
        =*%%@@%%%@@= :*%%-.-+=.        
      .+@@@@@@@%@+#@@%*+  :@+:.      
    :#@@@@*@@%@@@@@#@@@.=@ .@#-==:     
    =#@@@@@%*@@@@@@@#@@+.=* %%@@@%#+    
    +#*%@@@##@@@@@@#@@=-#.#%%@@=       
    .%#@=%@@@+@*@@@@#@%@+@*##@#  :%     
    %@%+@=%@@*++@@@#@%:++@*%-@=*@@     
    %%@@@+#%@@=%@@@#@%*+@+#*-#%@*%     
    .@%@%%@@%+%%:%%@##+:%*=%@@%%@*@.    
    -@@@.  .:-++=%@%*--++==:.  .@       
    -@%@:         **+*.        .%-+:    
    =#@#+=-:      ++      :--+*-+.     
    =@%@*#%@.            .@%#*#+ .     
    =@@@*+#+.             @%*#*  :     
    #@%%%*++-            =*+#= :-*     
    +@#@#=##=*+          #*+#*:--.@*    
    +@*@*-=# +-        -+.#=-*+-@*     
    :%#= .@.:+        +-:@. =#%-      
        -#*-@- #        #==@:*#-        
        .=#%*@        @*%*=.          
            :-        --              

          Brokenreality Inc.
         Gladiator ${git?.default?.tag}
    `;

    console.log(asciiArt);

  }, [git?.default?.tag]);

  return (
    <IonApp>
      <IonReactRouter>
        <UserContextProvider>
          <VolumeProvider>
            <PlayContextProvider>
              <PayPalScriptProvider options={paypalOptions}>
              <IonTabs>
                <IonRouterOutlet animated={false}>
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route exact path="/collection">
                        <Collection />
                    </Route>
                    <Route exact path="/collection/:collection/pg/:page">
                        <Collection />
                    </Route>
                    <Route exact path="/collection/:collection/:token">
                        <Collectible />
                    </Route>
                    <Route exact path="/collection/:collection">
                        <Collection />
                    </Route>
                    <Route exact path="/play">
                        <Lobby />
                    </Route>
                    <Route exact path="/play/battle">
                        <Play />
                    </Route>
                    <Route exact path="/leaderboard">
                        <Leaderboard />
                    </Route>
                    <Route exact path="/shop">
                      <Shop />
                    </Route>
                    <Route exact path="/maximus">
                      <Admin />
                    </Route>
                  </Switch>
                </IonRouterOutlet>
                <IonTabBar slot="bottom" className='dn' />
              </IonTabs>
              </PayPalScriptProvider>
            </PlayContextProvider>
          </VolumeProvider>
        </UserContextProvider>
      </IonReactRouter>
    </IonApp>
  )

};

export default App;
