import { objectPath_bedlams } from "..";
import { applyEffects, calculateTranslation } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";

/**
 * Dominance Protocol: A powerful ability applied to the source card
 * @param source Card activating the ability
 * @returns Boolean
 */
export function dominanceProtocol(source: TokenMetadata) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.wallet}_${source.collection}_${source.token_id}`);

        if (sourceElement) {
            applyEffects('dominance-protocol', 'cardAbility', sourceElement, undefined, 1200).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Chaotic Lash: An attack ability that affects a single target
 * @param source Card sending the attack
 * @param target Card receiving the attack
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function chaoticLash(source: TokenMetadata, target: TokenMetadata, context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.wallet}_${source.collection}_${source.token_id}`);
        const targetElement = document.getElementById(`card_${target.wallet}_${target.collection}_${target.token_id}`);

        if (sourceElement && targetElement) {
            const {
                translateX,
                translateY
            } = calculateTranslation(sourceElement, targetElement);
    
            sourceElement.style.transform = `translate(${translateX}px, ${translateY}px)`;
            applyEffects('chaotic-lash', context, targetElement, sourceElement, 1400).then(() => {
                sourceElement.style.transform = '';
                resolve(true);
            });
        }
    });
}

/**
 * Apex Strike: A decisive strike ability aimed at a single target
 * @param source Card sending the attack
 * @param target Card receiving the attack
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function apexStrike(source: TokenMetadata, target: TokenMetadata[], context: AbilityMode) {
    return new Promise<boolean>((resolve) => {
        const sourceElement = document.getElementById(`card_${source.wallet}_${source.collection}_${source.token_id}`);

        if (sourceElement && target) {

            /**
             * Insert animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx apex-strike center-stg ${context === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_bedlams}/fx/apex-strike/apex-strike-a2.png`;
            img.alt = 'apex-strike';
            fxDiv.appendChild(img);

            document.querySelector('#center-stage')?.appendChild(fxDiv);


            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the animation
                 */
                document.querySelector('.fx.apex-strike.center-stg')?.remove()

                resolve(true)

            }, 1900)

            /**
             * Display Battle Damage effect to the target
             */
            const targets = target
                .map(t => document.getElementById(`card_${t.wallet}_${t.collection}_${t.token_id}`))
                .filter(t => t !== null);

            applyEffects('apex-strike', context, targets as HTMLElement[], sourceElement, 1700).then(() => {
                resolve(true);
            });
        }
    });
}

/**
 * Corruption Wave: A group attack affecting multiple targets
 * @param source Card sending the attack
 * @param target Cards receiving the attack
 * @param context Ability mode or other context
 * @returns Boolean
 */
export function corruptionWave(source: TokenMetadata, target: TokenMetadata[], context: AbilityMode) {
    return new Promise<boolean>(async (resolve) => {
        const sourceElement = document.getElementById(`card_${source.wallet}_${source.collection}_${source.token_id}`);

        console.log('sourceElement', sourceElement)
        console.log('target', target)
        console.log('context', context)

        if (sourceElement && target) {

            /**
             * Insert animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx corruption-wave center-stg ${context === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_bedlams}/fx/corruption-wave/corruption-wave-a.png`;
            img.alt = 'corruption-wave';
            fxDiv.appendChild(img);

            document.querySelector('#center-stage')?.appendChild(fxDiv);

            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the animation
                 */
                document.querySelector('.fx.corruption-wave.center-stg')?.remove()

                resolve(true)

            }, 1900)

            /**
             * Display Battle Damage effect to the target
             */
            const targets = target
                .map(t => document.getElementById(`card_${t.wallet}_${t.collection}_${t.token_id}`))
                .filter(t => t !== null);

            console.log('targets', targets)

            applyEffects('corruption-wave', context, targets as HTMLElement[], sourceElement, 1500).then(() => {
                resolve(true);
            });
        }
    });
}
