import { IonButton, IonContent, IonFooter, IonIcon, IonModal, useIonAlert } from '@ionic/react'
import { useContext, useState } from 'react'
import { UserState } from '../../interfaces/User.interface';
import { UserContext } from '../../Context';
import './BottomNav.scss';
import { PlayContext, PlayState } from '../../pages/PlayContext';
import { closeOutline, home, homeSharp, trophySharp } from 'ionicons/icons';
import singlePlayer from './../../images/modes/single-player.png';
import pvp from './../../images/modes/pvp.png';
import { createRoom } from '../../services/GameServer';
import collectionIcon from '../../images/icons/cards.svg';
import swordsIcon from '../../images/icons/swords.svg';
import wearablesIcon from '../../images/icons/wearables.svg';

function BottomNav() {

  const { router, user, setGameMode } = useContext<UserState>(UserContext);
  const { setRoom }: PlayState = useContext(PlayContext);
  const [modal, displayModal] = useState(false); 
  const [presentAlert] = useIonAlert();

  if (!user.player?.uuid) return (<></>)

  return (
    <>

        {/* Play Mode */}
        <IonModal className="choose-mode" isOpen={modal} backdropDismiss={true} onDidDismiss={() => displayModal(false)}>
            <IonContent className="relative">
              <div className="modal-content tc flex flex-column">

                <div className='mv2'>
                  <IonButton 
                    color='transparent'
                    // Disabled if the user doesn't have any NFTs
                    onClick={() => {

                      // Set game mode to single player
                      setGameMode({ 
                        label: 'Brawler',
                        disabled: false,
                        description: 'Fast-paced matches. Direct NFT vs NFT battle.',
                        value: 'SB',
                        parent: 'Single Player'
                      })

                      // Create single player game and redirect to play
                      if (user.wallet) {
                        createRoom(user, `battle`, true).then((room) => {
                          setRoom(room);
                          router?.push(`/play/battle`)
                        }).catch((e) => {
                            presentAlert({
                            mode: 'ios',
                            header: 'Arena Error',
                            message: `The gods are not in our favor. Unable to connect to the server. Please try again later, brave gladiator.`,
                            buttons: ['OK'],
                            })
                        });
                      }

                      setTimeout(() => {
                        displayModal(false)
                      }, 250)

                    }}
                  >
                    <span className="flex flex-column mw5">
                      <img src={singlePlayer} alt="" />
                      <span className="dib pt2 gladiator-arena">Single Player</span>
                    </span>
                  </IonButton>
                </div>

                <div className='mv2'>
                  <IonButton 
                    color='transparent'
                    onClick={() => {

                      // Set game mode to multi player
                      setGameMode({ 
                        label: 'Brawler',
                        disabled: false,
                        description: 'Fast-paced matches. Direct NFT vs NFT battle.',
                        value: 'MB',
                        parent: 'Multi Player'
                      })

                      // Redirect to play where the player can access the lobby
                      router?.push('/play')

                      setTimeout(() => {
                        displayModal(false)
                      }, 250)

                    }}
                  >
                    <span className="flex flex-column mw5">
                      <img src={pvp} alt="" />
                        <span className="dib pt2 gladiator-arena">Player vs. Player</span>
                    </span>
                  </IonButton>
                </div>

                <div className='mt4'>
                  <IonButton className="cancel" color={'light'} onClick={() => displayModal(false)}><IonIcon icon={closeOutline} /></IonButton>
                </div>

              </div>

            </IonContent>
        </IonModal>

    <IonFooter>
      <div className="w-100 flex justify-center mv2 bottom-nav">
        <IonButton 
            color={'transparent'}
            className={`mr3 ${router?.routeInfo.pathname === '/' ? 'selected' :  ''}`}
            onClick={() => {
              router?.push('/')
            }}
          >
            <IonIcon icon={homeSharp} className='mr2-l' />
            <span className="button-text f5 show-for-large">Home</span>
          </IonButton>
          <IonButton 
            color={'transparent'}
            className={`mr3 ${router?.routeInfo.pathname === '/collection' ? 'selected' :  ''}`}
            onClick={() => {
              router?.push('/collection')
            }}
          >
            <IonIcon src={collectionIcon} className='mr2-l' />
            <span className="button-text f5 show-for-large">Collection</span>
          </IonButton>
          <IonButton 
            color='primary'
            className='mr3'
            disabled={user?.collectibles?.length === 0 ? true : false}
            onClick={(e) => {
              displayModal(true)
            }}
          >
            <IonIcon src={swordsIcon} className='mr2-l' />
              <span className="button-text f5 show-for-large">Play</span>
            </IonButton>
          <IonButton 
            color={'transparent'}
            className={`mr3 ${router?.routeInfo.pathname === '/leaderboard' ? 'selected' :  ''}`}
            onClick={() => {
              router?.push('/leaderboard')
            }}
          >
            <IonIcon icon={trophySharp} className='mr2-l' />
            <span className="button-text f5 show-for-large">Leaderboard</span>
          </IonButton>
          <IonButton 
            color='transparent'
            className={`mr3 ${router?.routeInfo.pathname === '/shop' ? 'selected' :  ''}`}
            onClick={() => {
              router?.push('/shop')
            }}
          >
            <IonIcon src={wearablesIcon} className='mr2-l' />
            <span className="button-text f5 show-for-large">Shop</span>
          </IonButton>
      </div>
    </IonFooter>
    </>
  )
}

export default BottomNav