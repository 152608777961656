// React
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context';
import { PlayContext, PlayState } from './PlayContext';

// Ionic
import { Storage } from '@ionic/storage';
import { IonButton, IonContent, IonPage, IonSpinner } from '@ionic/react';

// Services
import { endTurn, leave, rematch } from '../services/GameServer';

// Components
import Backdrop from '../components/play/Backdrop';
import PlayerCard from '../components/play/PlayerCard';
import DigitalClock from '../components/play/DigitalClock';
import Energy from '../components/play/Energy';
import TurnCount from '../components/play/TurnCount';
import AbilityIcon from '../components/play/AbilityIcon';
import GladiatorCursor from '../components/play/GladiatorCursor';
import Power from '../components/play/Power';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Images
import turnIndicator from '../images/turn-indicator-assets/turn-indicator.png';
import turnIndicatorDial from '../images/turn-indicator-assets/turn-indicator-dial.png';
import full from '../images/energy-assets/energy-slot-full.png';

// Styles
import './Play.scss';
import './../fx/fx-importStyles';

// Utils
import { tilt } from '../Utils';
import GameSummaryDisplay from '../components/play/GameSummary';
import { transform } from 'lodash';
import Points from '../components/play/Points';

// Prepare local storage
const storage = new Storage();
storage.create();

const Play: React.FC = () => {

  // document.documentElement.style.cursor = 'none';
  document.documentElement.classList.add('play');
  

  const { router, user }: UserState = useContext(UserContext);
  const { 

    // State
    room,
    battleRoomState,
    chooseTokens,
    otherPlayerConnected,
    gameStart,
    player,
    myCards,
    myAbilities,
    myEnergy,
    opponent,
    opponentAbilities,
    displayYourTurn,
    displayEvaded,
    cardAbilityPlayed,
    displayAbilityPlayed,
    displayLose,
    displayWin,
    displayTie,
    opponentCardHover,
    opponentCards,
    opponentEnergy,
    randomBackdrop,
    myTurn,
    timerValue,
    turnCount,
    turnMax,
    gameOver,
    gameSummary,

    // Methods
    resetGame,
    setAwaitingRematchResponse

  }: PlayState = useContext(PlayContext);

  const [matchMessageInFront, setMatchMessageInFront] = useState(false);

  /**
   * Opponent Card Click Event
   * @param card Card Clicked
   * @param flipped If the card is already flipped
   */
  // const selfClick = (card: TokenMetadata) => {
  //   if (user.socket && myCard && abilitySelected && abilitySelected.self && room) {
  //     let target: TokenMetadata | TokenMetadata[];
  //     if (abilitySelected.group) {
  //       target = myCards;
  //     } else {
  //       target = card;
  //     }
  //     // ColosseumService.roomEvent(user, room, 'cardAbility', target, myCard, abilitySelected)
  //   }
  // };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    // console.log('room', room)
    // console.log('battleRoomState', battleRoomState)
    // console.log('currentTurn', battleRoomState?.current_turn)
    // console.log('gameStart', gameStart)
    // console.log('myCards', myCards)
    // console.log('opponentCards', opponentCards)
    // console.log('myTurn', myTurn)

    if (gameStart) {
      tilt('tilt-js-my-cards');
    }

  }, [battleRoomState, gameStart, myCards, opponentCards, gameOver, myTurn, myAbilities, opponentAbilities])

  useEffect(() => {
    // If either of these are set to true, we want to bring the match message to the front, and if they
    // change to false, we'll wait 500ms before sending it back to the back
    if (displayAbilityPlayed || displayYourTurn || displayEvaded) {
      setMatchMessageInFront(true);
    } else {
      setTimeout(() => {
        setMatchMessageInFront(false);
      }, 500);
    }
  }, [displayAbilityPlayed, displayYourTurn, displayEvaded])

  return (
    <>
    <GladiatorCursor />
    <IonPage className='gladiator-page play z-2'>

      {/* Multi Player Brawler */}
      {/* {user.mode && user?.mode.value === 'MB' && otherPlayerConnected ? ( */}
        <>

        {/* Battle Stage */}
        <IonContent fullscreen className='relative z-3'>

          {gameStart !== null && randomBackdrop ? (
            <Backdrop type={randomBackdrop} />
          ): null}

          <div className="player-elements">

            {gameStart !== null ? (
              <>
              <div id="hud-elements">

                {/* Power Circles: Opponent */}
                <div className="power-circles opponent flex-column">
                  <h2 className="power-label gladiator-arena f5 mb0">Power</h2>
                  <Power tokens={opponentCards} />
                </div>

                {/* Energy: Opponent */}
                <div className="energy-meter opponent">

                  {/* Mobile Energy Display */}
                  <div className="tc energy-label hide-for-medium">
                    <h2 className="gladiator-arena f6 mb1 mt3 mh2">Energy</h2>
                    <div className="gladiator-arena mt0 f3 mb0 flex justify-center items-center relative">
                      <img src={full} className="full mr1" alt='Energy Slot Full' style={{
                        width: '1rem',
                        height: '100%',
                        transform: 'translateY(-0.5rem)'
                      }} />
                      <span>
                        <span className="f5 mr1">x</span>{opponentEnergy ? opponentEnergy : 0}
                      </span>
                    </div>
                  </div>

                  {/* Tablet / Desktop Energy Display */}
                  <div className="tc energy-label show-for-medium">
                    <h2 className="gladiator-arena f5 mb1 mt0">Energy</h2>
                    <p className="gladiator-arena mt0 f3 mb0">{opponentEnergy ? opponentEnergy : 0}/10</p>
                  </div>
                  <Energy energyValue={opponentEnergy} />

                </div>

                {/* Points: Opponent */}
                <Points context='opponent' points={opponent?.points ? opponent.points : 0} />

                {/* Energy: Player */}
                <div className="energy-meter player">

                  {/* Mobile Energy */}
                  <div className="tc energy-label hide-for-medium">
                    <h2 className="gladiator-arena f6 mb1 mt3 mh2">Energy</h2>
                    <div className="gladiator-arena mt0 f3 mb0 flex justify-center items-center relative">
                      <img src={full} className="full mr1" alt='Energy Slot Full' style={{
                        width: '1rem',
                        height: '100%',
                        transform: 'translateY(-0.5rem)'
                      }} />
                      <span>
                        <span className="f5 mr1">x</span>{myEnergy ? myEnergy : 0}
                      </span>
                    </div>
                  </div>

                  {/* Tablet / Desktop Energy */}
                  <Energy energyValue={myEnergy} />
                  <div className="tc energy-label show-for-medium">
                    <h2 className="gladiator-arena f5 mb1 mt1">Energy</h2>
                    <p className="gladiator-arena mt0 f3 mb0">{myEnergy ? myEnergy : 0}/10</p>
                  </div>

                </div>

                {/* Power Circles */}
                <div className="power-circles player flex-column">
                  <Power tokens={myCards} />
                  <h2 className="power-label gladiator-arena f5 mb0 z-1">Power</h2>
                </div>

                {/* Points: Player */}
                <Points context='player' points={player?.points ? player.points : 0} />

              </div>

              {!gameOver ? (
                <div id="hud-elements-fg">
                  <div className="abilities-played">
                    <div className="opponent-abilities">
                      {opponentAbilities?.map((ability, i: number) => {
                        return (
                          <div key={`opponent_ability_${ability.name}_${i}`} className="ability-icon-container">
                            <div className="ability animate__animated animate__bounceIn">
                              <AbilityIcon ability={ability} />
                              {/* <p className="label gladiator-arena">{ability.name}</p> */}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="my-abilities">
                      {myAbilities?.map((ability, i: number) => {
                        return (
                          <div key={`my_ability_${ability.name}_${i}`} className="ability-icon-container">
                            <div className="ability animate__animated animate__bounceIn">
                              <AbilityIcon ability={ability} />
                              {/* <p className="label gladiator-arena">{ability.name}</p> */}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <button className={`turn-indicator ${myTurn ? `your-turn pointer` : ``}`}
                    onClick={(e) => { if (room) { endTurn(room); } }}
                    disabled={!myTurn}
                  >
                    <img className='indicator' src={turnIndicator} alt="" />
                    <img className='dial' src={turnIndicatorDial} alt="" />
                  </button>
                  <div className="turn-clock tc">
                    <div className="tc mt3 turn-timer-label">
                        <p className="gladiator-arena mv0" style={{ 
                          transition: 'all 0.5s',
                          color: myTurn ? '#0F0' : '#FF0000',
                          textShadow: myTurn ? '0 0 5px #006400, 0 0 10px #006400, 0 0 15px #009900, 0 0 20px #009900, 0 0 25px #009900' : '0 0 5px #B22222, 0 0 10px #B22222, 0 0 15px #D43A3A, 0 0 20px #D43A3A, 0 0 25px #D43A3A'
                        }}>
                          {myTurn ? 'Your Turn' : 'Wait'}
                        </p>
                    </div>
                    {(myTurn !== null) ? (
                      <DigitalClock myTurn={myTurn} timerValue={timerValue} />
                      ) : null}
                      <TurnCount turnCount={turnCount} turnMax={turnMax} />
                      <IonButton 
                        className={`end-turn-button ${myTurn && myEnergy === 0 ? `pulse` : ``}`}
                        disabled={!myTurn}
                        onClick={(e) => { if (room) { endTurn(room); } }} color={'transparent'}
                      >
                        <span className="dib pt2 gladiator-arena">
                          {myTurn ? 'Done' : 'Wait'}
                        </span>
                      </IonButton>
                  </div>
                </div>
              ) : null}

              </>
            ) : null}

            {/* Opponent Cards */}
            {gameStart !== null ? (
            <div className={`player-cards top card-count-${opponentCards ? opponentCards.length : 0}`}>
            {opponentCards?.map((card, i: number) =>  (
              <PlayerCard 
                key={`player_card_${card.wallet}_${card.token_id}_${card.collection}_${i}`} 
                display='opponent'
                card={card}
                hovered={ (opponentCardHover && opponentCardHover.token_id === card.token_id) ? true : false }
              />
            ))}
            </div>
            ) : null}

            {gameStart !== null ? (
              <>
              <div id="center-stage" />
              <div id="match-message" className={`${matchMessageInFront ? 'z-10' : 'z-1'}`}>
                <div className="ability-played-container text-drop-shadow">
                    <div className={`ability-played animate__animated ${displayAbilityPlayed ? `animate__bounceInLeft` : `animate__bounceOutRight`}`}>
                      <h1 className='gladiator-arena mv0 pv0'>{cardAbilityPlayed?.name}</h1>
                    </div>
                  </div>
                  <div className="your-turn-container text-drop-shadow">
                    <div className={`your-turn animate__animated ${displayYourTurn ? `animate__zoomIn` : `animate__zoomOut`}`}>
                      {myTurn && <h1 className='gladiator-arena mv0 pv0'>Your Turn!</h1>}
                    </div>
                  </div>
                  <div className="evaded-container text-drop-shadow">
                    {displayEvaded ? (
                      <div className={`evaded animate__animated ${displayEvaded ? `animate__zoomIn` : `animate__zoomOut`}`}>
                        {<h1 className='gladiator-arena mv0 pv0'>Evaded!</h1>}
                      </div>
                    ) : null}
                  </div>
              </div>

              {gameOver ? (
                  <div className="win-lose-container">
                    <div className={`win-lose animate__animated ${displayLose || displayWin ? `animate__bounceIn` : ``}`}>
                      <div className="db mb3">
                        {displayWin ? <h1 className='gladiator-arena mv0 pv0'>You<br />won!</h1> : null }
                        {displayLose ? <h1 className='gladiator-arena mv0 pv0'>You<br />lost!</h1> : null }
                        {displayTie ? <h1 className='gladiator-arena mv0 pv0'>Tie<br />game!</h1> : null }
                      </div>
                      {(gameSummary && displayWin) && (player && opponent) ? (
                        <GameSummaryDisplay player={player} opponent={opponent} gameSummary={gameSummary} mode='winner' />
                      ) : null}
                      {(gameSummary && displayLose) && (player && opponent) ? (
                        <GameSummaryDisplay player={player} opponent={opponent} gameSummary={gameSummary} mode='loser' />
                      ) : null}
                      {(gameSummary && displayTie) && (player && opponent) ? (
                        <GameSummaryDisplay player={player} opponent={opponent} gameSummary={gameSummary} mode='tie' />
                      ) : null}
                      <div className="tc">
                        {!battleRoomState?.singlePlayer ? (
                          <IonButton size='large' onClick={(e) => {
                            if (room) {
                              setAwaitingRematchResponse(true)
                              rematch(user, room);
                            }  
                          }} color={'transparent'}><span className="button-text">Rematch</span></IonButton>
                        ) : null}
                        <IonButton size='large' onClick={(e) => {
                          if (room) {
                            leave(user, room).then(() => {
                              resetGame(false)
                              if (battleRoomState?.singlePlayer) {
                                router?.push('/')
                              } else {
                                router?.push('/play')                            
                              }
                            });
                          }
                        }} color={'transparent'}><span className="button-text">Leave</span></IonButton>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}

            {/* Player Cards */}
            {gameStart !== null ? (
            <div className={`player-cards bottom card-count-${myCards ? myCards.length : 0}`}>
              {myCards?.map((card, i: number) =>  (
                <PlayerCard 
                  key={`player_card_${card.wallet}_${card.token_id}_${card.collection}_${i}`} 
                  display='player'
                  card={card}
                />
              ))}
              <div id="tilt-js-my-cards" />
            </div>
            ) : null}

          </div>
        </IonContent>
        <div className="battle-fx" />

          {/* If we have chosen our tokens and we're waiting on the opponent */}
          {!chooseTokens && !battleRoomState?.singlePlayer && gameStart === null && !otherPlayerConnected ? ( // Check if opponent is not ready
              <div className="waiting-on-opponent-container tc text-drop-shadow animate__animated animate__zoomIn">
                  <IonSpinner 
                    name="crescent"
                    style={{
                      transform: 'scale(2.5)',
                    }}
                   />
                  <p className="pt-serif b f4 mt4 pt3 ttu">Waiting for opponent...</p>
              </div>
          ) : null}

        </>
    </IonPage>
    </>
  );
};

export default Play;
