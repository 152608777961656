// Interfaces
import { loadCollections } from "../supabase/api";
import { CollectionDetails, LocalCollectionProperties } from "./collections.interface";

// Collection slugs
export enum CollectionSlug {
  CosmicMuffins = 'cosmicmuffins',
  SacredMoths = 'sacredmoths',
  Philosophers = 'philosophers',
  Buns = 'buns',
  Bedlams = 'bedlams',
  Bufficorns = 'bufficorns',
};

// Collection Order
const CollectionOrder: string[] = Object.values(CollectionSlug);

// Spaces Object Storage
export const spacesStorage = 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections';

// Ensure the require.context function is available in a TypeScript environment
declare global {
  interface NodeRequire {
    context: (directory: string, useSubdirectories: boolean, regExp: RegExp) => any;
  }
}

/**
 * Import all collection data
 * @returns Collection Details
 */
const importAllCollections = async (): Promise<LocalCollectionProperties[]> => {
  const context = require.context('../collections', true, /index\.tsx$/);
  const imports = context.keys().map(context);
  const collections: LocalCollectionProperties[] = [];
  for (const importPromise of imports) {
    const module = await importPromise;
    collections.push(module.default);
  }
  return collections;
};

/**
 * Import all abilities
 * @returns Collection Abilities
 */
export const initAbilities = async (): Promise<any[]> => {
  const context = require.context(`../collections/`, true, /\/abilities\/index\.tsx$/);
  const imports = context.keys().map(context);  
  const abilities: any[] = [];

  // Loop through each module and push the default export to the abilities array
  for (const importPromise of imports) {
    const module: any = await importPromise;
    module.default.forEach((ability: any) => {
      abilities.push(ability);
    });
  }

  return abilities;
};

/**
 * Initialize collections
 * @returns Collection Details
 */
export const initCollections = async (): Promise<CollectionDetails[]> => {

  // console.log('Loading collections...');

  const enabledCollections = await loadCollections();

  // console.log('Enabled collections:', enabledCollections);

  const collections = await importAllCollections();

  // console.log('Local collection data:', collections);

  const collectionDetails: CollectionDetails[] = [];

  // Evaluate which collections are enabled based on API response
  collections.forEach((collection) => {
    const enabledCollection = enabledCollections.find((c) => c.slug === collection.slugLocal && c.enabled);
    if (enabledCollection) {
      collectionDetails.push({
        ...collection,
        ...enabledCollection
      });
    }
  });

  const sortedCollections = collectionDetails.sort((a, b) => CollectionOrder.indexOf(a.slug as string) - CollectionOrder.indexOf(b.slug as string)).filter((collection) => collection.enabled);

  console.log('Merged and Sorted collections:', sortedCollections);

  return sortedCollections;
};