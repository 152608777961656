// Supabase
import { Session } from '@supabase/supabase-js';
import { api } from '../Env';
import { supabase } from './api';
import { Web3Provider } from '../web3/web3';
import { PlayerRow } from './types';

/**
 * Check for an existing player by wallet address
 * This references the auth table to get the user ID
 * @param walletAddress Wallet address
 * @returns User ID from the auth table
 */
export async function getPlayerIDByWalletAddress(walletAddress: string) {
    return new Promise<string | null>(async (resolve, reject) => {

        const { data, error } = await supabase
            .rpc('get_user_by_wallet', { 
                wallet_address: walletAddress 
            });
    
        if (error) {
            console.error('Error fetching user by wallet address:', error);
            reject(error);
        }
    
        if (data.length === 0) {
            resolve(null);
        } else {
            resolve(data[0].user_id);
        }

    });
}

/**
 * Get a player by their wallet address
 * @param walletAddress Wallet address
 * @returns Player object
 */
export async function getPlayerByWallet(walletAddress: string) {
    return new Promise<PlayerRow | null>(async (resolve, reject) => {

        const { data, error } = await supabase
            .from('players')
            .select('*')
            .eq('wallet_id', walletAddress)
            .single();

        if (!data) {
            resolve(null);
        } else {
            resolve(data as PlayerRow);
        }

    });
}

/**
 * Sign in a user anonymously with a wallet address
 * @param walletAddress Wallet address
 * @returns User object
 */
export async function signInAnonymouslyWithWallet(walletAddress: string, provider: Web3Provider) {
    return new Promise<PlayerRow>(async (resolve, reject) => {

        // Check if a user with this wallet address already exists
        let userID = await getPlayerIDByWalletAddress(walletAddress);

        // If there is no record in the auth table, create a new user
        if (!userID) {

            console.log('User not found, creating new user');

            const { data, error } = await supabase.auth.signInAnonymously({
                options: {
                    data: {
                        walletAddress: walletAddress,
                        provider: provider.name
                    }
                }
            });

            if (error) {
                throw error;
            }

            // Insert the new player record
            const { data: player, error: playerError } = await supabase.from('players').insert({
                uuid: data.user?.id,
                wallet_id: walletAddress,
                provider: provider.name
            }).select('*').single();

            if (playerError) {
                reject(playerError);
            }

            // console.log('New user created', player);

            if (!player) {
                reject('Error getting single record after inset');
            } else {

                // console.log('New user created', player);

                resolve(player as PlayerRow);
            }

        // If there is a user record in the auth table
        } else {

            // console.log('User found', userID);

            // Check if the user is logged in locally
            userLoggedIn().then(async (loggedIn) => {

                // If not, and we have an older user ID, allow the user to sign in anonymously
                if (!loggedIn && userID) {

                    console.log('User is not logged in', userID);

                    // Sign in the user anonymously
                    const { data, error } = await supabase.auth.signInAnonymously({
                        options: {
                            data: {
                                walletAddress: walletAddress,
                                provider: provider.name
                            }
                        }
                    });

                    if (error) {
                        throw error;
                    }

                    try {

                        // Set the old and new user IDs from the anonymous sign ins
                        const oldUserId = userID;
                        const newUserId = data.user?.id!;

                        // Make an RPC call to update player UUID reference in all database tables
                        supabase.rpc('update_player_uuids', {
                            old_user_id: oldUserId,
                            new_user_id: newUserId,
                            wallet_address: walletAddress
                        }).then(async ({ data, error }) => {

                            if (error) {
                                console.error('Error updating player UUIDs:', error);
                                throw error;
                            }

                            // console.log(`Player UUIDs updated from ${oldUserId} to ${newUserId}`, data);

                            // Fetch the updated player record
                            const { data: playerData, error: playerErrorData } = await supabase.from('players')
                                .select('*').eq('uuid', newUserId).single();

                            if (playerErrorData) {
                                reject(playerErrorData);
                            }

                            if (!playerData) {
                                reject('Error getting single record after update');
                            } else {
                                console.log('Player UUIDs updated', data);
                                resolve(playerData as PlayerRow);
                            }

                        });

                    } catch (error) {
                        
                        console.error('Error updating player record:', error);
                        reject(error);

                    }

                } else {

                    // console.log('User is already logged in', walletAddress);
                    getPlayerByWallet(walletAddress).then((player) => {
                        if (!player) {
                            reject('Error getting player record');
                        } else {
                            // console.log('Player record found', player);
                            resolve(player);
                        }
                    });

                }

            })

        }

    });
}

/**
 * Create or get a player record
 * @param walletAddress Wallet address
 * @param session Supabase session
 * @returns Player object
 */
export async function createOrGetPlayer(walletAddress: string, session: Session): Promise<PlayerRow | null> {

    // Check if a user with this wallet address already exists
    let player = await getPlayerByWallet(walletAddress);

    // If there is no player record, create a new player
    if (!player) {

        const { data, error } = await supabase.from('players').insert({
            uuid: session.user.id,
            wallet_id: walletAddress,
            provider: session.user.app_metadata.provider
        }).select('*').single();

        if (error) {
            console.error('Error inserting new player record:', error);
        } else {
            player = data as PlayerRow;
        }

    }

    return player;

}

/**
 * Check if the user is logged in
 * @returns boolean
 */
export async function userLoggedIn(): Promise<Session | null> {
    const session = await supabase.auth.getSession();
    return session.data.session ? session.data.session : null;
};

/**
 * Sign up a new user
 * @param email Email address
 * @param password Password
 * @returns Deconstructed data object
 */
export async function signUpNewUser(email: string, password: string, accountType: string) {
    const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
            emailRedirectTo:  `${api()}/`,
            data: {
                accountType
            }
        }
    })

    if (error != null) {
        throw error
    }

    return data
}

/**
 * Sign in a user with email and password
 * @param email Email address
 * @returns Decostructed data object
 */
export async function signInWithEmail(email: string) {

    const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: {
            shouldCreateUser: true,
        }
    })

    if (error != null) {
        throw error
    }

    return data
}

/**
 * Verify the OTP
 * @param email Email address
 * @param token OTP token
 * @returns Decostructed data object
 */
export async function verifyOtp(email: string, token: string) {
    const { data, error } = await supabase.auth.verifyOtp({ email, token, type: 'email'})

    if (error != null) {
        throw error
    }

    return data
}

/**
 * Sign out the current user
 */
export async function signOutUser() {
    const { error } = await supabase.auth.signOut()

    if (error != null) {
        throw error
    }

    return true
}

/**
 * Get the current user
 * @returns User object
 */
export async function getUser() {
    const { data: { user }, error } = await supabase.auth.getUser()

    if (error != null) {
        throw error
    }

    return user
}


/**
 * Send a password reset email
 * @param email Email address
 */
export async function forgotPassword(email: string) {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `gladiator:///reset-password`
    });

    if (error != null) {
        throw error;
    }

    return true;
}

/**
 * Reset the user's password
 * @param access_token Access token
 * @param password New password
 */
export async function resetPassword(password: string) {
    const { data: { user }, error } = await supabase.auth.updateUser({
        password: password,
    });

    if (error != null) {
        throw error;
    }

    return user;
}

/**
 * Create a new session from account recovery access_token 
 * @param access_token Access token
 */
export async function createSession(access_token: string, refresh_token: string) {
    const { data, error } = await supabase.auth.setSession({
        access_token: access_token,
        refresh_token: refresh_token
    })

    if (error != null) {
        throw error;
    }

    return data;
}
