// React
import React, { useEffect, useState } from 'react';

// React Spring
import { useSpring, animated } from 'react-spring';

interface PointsProps {
    context: 'player' | 'opponent';
    points: number;
}

const Points: React.FC<PointsProps> = ({
    context,
    points
}) => {
    const [props, set] = useSpring(() => ({
        number: points,
        from: { number: 0 },
        config: { tension: 100, friction: 26 }
    }));

    const [flashColor, setFlashColor] = useState(false);

    useEffect(() => {
        const delayTimeout = setTimeout(() => {
            setFlashColor(true);
            set({ number: points });
            const flashTimeout = setTimeout(() => {
                setFlashColor(false);
            }, 2000);
            return () => clearTimeout(flashTimeout);
        }, 500);

        return () => clearTimeout(delayTimeout);
    }, [points, set]);

    return (
        <div className={`points ${context} pt-serif b lh-title f4 f3-l flex flex-column`}>
            <p className="mv0 ttu f7 show-for-medium">Points:</p>
            <animated.p
                className={`f5 f4-m f3-l ${context} mv0`}
                style={{ 
                    transition: 'all 0.5s',
                    color: flashColor ? '#F2D06A' : 'inherit' ,
                    // outer glow
                    textShadow: flashColor ? '0 0 10px #F2D06A' : '0 0 0 #F2D06A'
                }}
            >
                {props.number.to(n => Math.round(n).toLocaleString())}
            </animated.p>
        </div>
    );
};

export default Points;