// React
import { UserContext } from '../Context';
import { useContext, useEffect, useState } from 'react';

// Ionic
import { getPlatforms, IonAlert, IonButton, IonContent, IonIcon, IonInput, IonLoading, IonModal, IonSpinner } from '@ionic/react';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Styles
import './Home.scss';

// Images
import card from './../images/gladiator/gladiator-card.png'
import logo from './../images/gladiator/gladiator-logo.png'

// Components
import Version from '../components/ui/Version';

// Utils
import { shortenWalletAddress, tilt } from '../Utils';

// Web3
import { isFrame, isMetaMask, Web3Provider, Web3Providers } from '../web3/web3';
import { supabase } from '../supabase/api';
import { chevronForward } from 'ionicons/icons';
import { getUser, signInWithEmail, verifyOtp } from '../supabase/auth';
import OtpInput from './Login-OneTimePasscode';
import { useParams } from 'react-router';

/**
 * Connected SVG (animated checkmark)
 * @returns JSX Element
 */
function ConnectedSVG() {
    return (
        <svg className="checkmark ml2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    );
}

// For Google Sign-In
export const signInWithGoogle = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google'
    });

    console.log('data:', data);

    console.log('data.url:', data?.url);
    // if (data?.url) {
    //   await Browser.open({ url: data.url });
    // }

    if (error) console.error('Google Sign-In Error:', error);
};

// For Apple Sign-In
export const signInWithApple = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'apple',
        options: {
            redirectTo: `${window.location.origin}/auth/callback`,
        },
    });

    // if (data?.url) {
    //   await Browser.open({ url: data.url });
    // }

    if (error) console.error('Apple Sign-In Error:', error);
};

const Login: React.FC = () => {

    const platforms = getPlatforms();
    const { 
        startSession,
        user, 
        loggedIn, 
        tokensLoaded, 
        chooseProvider, 
        setWaitModal 
    } = useContext<UserState>(UserContext);

    const [modal, displayModal] = useState(false);
    const [activating, setActivating] = useState<Web3Provider | null>();
    const [showAlert, setShowAlert] = useState<Web3Provider | null>();

    // Email User Access
    const [isTouched, setIsTouched] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [sendingEmail, setSendingEmail] = useState(false);
    const [sentEmail, setSentEmail] = useState(false);
    const [sendEmailError, setEmailSendError] = useState(false);
    const [validEmailError, setValidEmailError] = useState(false);

    // One Time Passcode
    const [displayOtp, setDisplayOtp] = useState(false);
    const [otpError, setOtpError] = useState(false);

    /**
     * Mark Email Input Touched
     */
    const markTouched = () => {
        setIsTouched(true);
    };

    /**
     * Handle Provider Click
     * @param provider Web3 Provider
     */
    const handleProviderClick = (provider: Web3Provider) => {
        if (provider.name === 'MetaMask' && !isMetaMask) {
            setShowAlert(provider);
        } else if (provider.name === 'Frame' && !isFrame) {
            setShowAlert(provider);
        } else {
            chooseProvider(provider);
            setActivating(provider);

            // Deeplink for iOS users
            if (provider.name === 'MetaMask' && platforms.includes(`ios`) && !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) {
                window.open("https://metamask.app.link/dapp/gladiator.brokenreality.com");
            }
        }
    };

    /**
     * Handle Alert Confirm
     */
    const handleAlertConfirm = () => {
        setWaitModal(false);
        setShowAlert(undefined);
        if (showAlert?.name === 'MetaMask') {
            window.open('https://metamask.io/download.html', '_blank');
        }
        if (showAlert?.name === 'Frame') {
            window.open('https://frame.sh/', '_blank');
        }
    };

    /**
     * Handle Alert Dismiss
     */
    const handleAlertDismiss = () => {
        setWaitModal(false);
        setShowAlert(undefined);
    };

    /**
     * Validate Email
     * @param email 
     * @returns 
     */
    const validateEmail = (email: string) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    /**
     * Handle Email Submit
     */
    const handleEmailSubmit = async () => {
        
        if (!validateEmail(email)) {
            setValidEmailError(true);
            return;
        } else {
            setValidEmailError(false);
        }
        setSendingEmail(true);
        await signInWithEmail(email).then((response) => {
            setSendingEmail(false);
            setSentEmail(true);
        }).catch((error) => {
            setSendingEmail(false);
            setEmailSendError(true);
            console.error('Email Sign-In Error:', error);
        });

    }

    useEffect(() => {
        if (activating && user?.wallet) {
          setActivating(null);
          setTimeout(() => {
            displayModal(true);
          }, 250);
        }
    }, [platforms, activating, user?.wallet])

    // Initialize tilt on mount
    useEffect(() => {
        initTilt()
    }, [])

    /**
     * Initialize vanilla tilt
     */
    const initTilt = (timeout: number = 100) => {
        setTimeout(() => {
            tilt('tilt-js-welcome');
        }, timeout)
    }

    return (
        <>
        <div className="gladiator-wallet-container">
            <div className={`animate__animated ${!tokensLoaded ? `animate__zoomIn` : `animate__zoomOut`} flex flex-column`}>
                <div className="mb3 tc">
                    <div className="gladiator-logo"
                        data-tilt
                        data-tilt-max="12"
                        data-tilt-full-page-listening
                        data-tilt-reverse="true"
                    >
                        <img src={card} alt="" width={350} className='left' />
                        <img src={card} alt="" width={350} className='right' />
                        <img src={logo} alt="" width={350} className='logo' />
                    </div>
                </div>
                <div className='db tc o-90 lh-copy mb3'>
                    <p className='mb0 gladiator-arena f4'>An unparalleled digital<br />collectible card game experience.</p>
                </div>
                <div className="row">
                    <div className="column small-12 tc mt3">
                        <IonButton id="connect-wallet-button-header" className="white-outline-wallet animate__animated animate__zoomIn" color={user?.wallet === null ? `primary` : 'light'} onClick={() => displayModal(true)}>
                            <span className="button-text f4">Begin</span>
                        </IonButton>
                    </div>
                </div>
                <div className="row">
                    <div className="column small-12 o-50 mt4 f6 tc">
                        <p className='mb0'></p>
                        <p className='mb0'>&copy; {new Date().getFullYear()} Brokenreality Inc. <Version /></p>
                    </div>
                </div>
            </div>

        </div>
        <div id="tilt-js-welcome"></div>

            {/* Connect Modal */}
            <IonModal 
                className="connect-wallet"
                isOpen={modal}
                backdropDismiss={true}
                onDidDismiss={() => displayModal(false)}
            >
                <IonContent className="relative">
                <div className='relative modal-content z-1'>
                        <div className="row mv2">
                            <div className="column small-12">
                                <div className="ph3">
                                    <h1 className="mb0 pr3 gladiator-arena"><span className="dib pt2">Connect to<br />Gladiator</span></h1>
                                    {!displayOtp ? (
                                        <h2 className="mt0 lh-copy f6 pt-serif ttu fw6">Choose provider</h2>
                                    ) : (
                                        <h2 className="mt0 lh-copy f6 pt-serif ttu fw6">Enter One Time Passcode</h2>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mb4 flex justify-center">
                            {!displayOtp ? (
                                <>
                                {Web3Providers.map(provider => {
                                    if (provider.connect) {
                                        const connected = provider.name === user?.provider?.name;
                                        const disabled = ((user?.wallet && user?.provider?.id && loggedIn) && (user?.provider?.id !== provider.id)) ? true : false;
                                        return (
                                            <div key={`${provider.id}`} className="column small-12 flex justify-center ma0 pa0">
                                                <IonButton
                                                    className="w5"
                                                    disabled={disabled}
                                                    color="light"
                                                    onClick={() => {
                                                        handleProviderClick(provider)
                                                    }}>
                                                    <span className='dib flex items-center'><img width={25} height={25} src={provider.image} alt="" className="wallet-logo mr2" /> <span className='button-text'>{provider.name}</span></span>
                                                    &nbsp;{(activating && activating.id === provider.id) && <IonSpinner className="dn" name="crescent" />}{((!activating && connected && loggedIn) && (provider.id === user?.provider?.id)) && (<ConnectedSVG />)}
                                                </IonButton>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                <div className="column small-12 flex justify-center items-center mt3 fade-in">
                                    <p className='lh-copy f6 pt-serif ttu fw6 mv0'>Or</p>
                                </div>
                                <div className="column small-12 flex justify-center items-center mt3 fade-in">
                                    <form 
                                        id="email-form"
                                        className="w-100 mh3 relative"
                                        onSubmit={async (e) => {
                                            e.preventDefault();
                                        }}
                                        style={{
                                            maxWidth: '255px'
                                        }}
                                    >
                                        <IonInput
                                            mode='md'
                                            className="w-100 gladiator-input pt-serif padding-end"
                                            labelPlacement="stacked" 
                                            fill="outline" 
                                            placeholder="Continue with email"
                                            value={email}
                                            type='email'
                                            required
                                            onIonBlur={() => markTouched()}
                                            onInput={e => {
                                                const value = (e.target as HTMLInputElement).value
                                                setEmail(value)
                                            }}
                                            style={{
                                                textAlign: 'left'
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleEmailSubmit();
                                                }
                                            }}
                                        />
                                        <IonButton 
                                            className='chevron-form-submit'
                                            mode='md'
                                            type='submit'
                                            color="transparent"
                                            onClick={() => handleEmailSubmit()}
                                        >
                                            <IonIcon icon={chevronForward} />
                                            <span className="show-for-sr">Continue</span>
                                        </IonButton>
                                    </form>
                                </div>
                                </>
                            ) : (
                                <>
                                <OtpInput 
                                    onComplete={(code) => {
                                        verifyOtp(
                                            email,
                                            code
                                        ).then((response) => {
                                            if (response.session) {
                                                displayModal(false);
                                                startSession(response.session);
                                            }
                                        }).catch((error) => {
                                            console.error('Otp Error:', error);
                                            setOtpError(true);
                                        });
                                    }}
                                />
                                </>
                            )}

                        </div>

                        {/* <div className="row dn">
                            <div className="column small-12">
                                    <IonButton
                                        className="w5"
                                        color="light"
                                        onClick={ async () => {
                                            signInWithGoogle()
                                        }}>
                                        Sign in with Google
                                    </IonButton>
                            </div>
                        </div> */}

                        <IonAlert
                            mode='ios'
                            isOpen={showAlert !== null && showAlert !== undefined}
                            onDidDismiss={handleAlertDismiss}
                            header={`${showAlert?.name}`}
                            message={`${showAlert?.name} is not installed. Would you like to install it?`}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: handleAlertDismiss
                                },
                                {
                                    text: 'Install',
                                    handler: handleAlertConfirm
                                }
                            ]}
                        />
                
                        {/* Sending Email */}
                        <IonLoading
                            mode='ios'
                            isOpen={sendingEmail}
                            message={`Sending Email...`}
                        />

                        {/* Sent Email */}
                        <IonAlert
                            mode='ios'
                            isOpen={sentEmail}
                            header={`Email Sent!`}
                            message={`Please check your email and follow the instructions to continue.`}
                            buttons={[
                                {
                                    text: 'OK',
                                    handler: () => {
                                        setSentEmail(false);
                                        setDisplayOtp(true);
                                    }
                                }
                            ]}
                        />

                        {/* Email Send Error */}
                        <IonAlert
                            mode='ios'
                            isOpen={sendEmailError}
                            header={`Error Sending Email`}
                            message={`There was an error sending the email. Please try again or contact support.`}
                            buttons={[
                                {
                                    text: 'OK',
                                    handler: () => {
                                        setEmailSendError(false);
                                    }
                                }
                            ]}
                        />

                        {/* Valid Email Error */}
                        <IonAlert
                            mode='ios'
                            isOpen={validEmailError}
                            header={`Invalid Email`}
                            message={`Please enter a valid email address.`}
                            buttons={[
                                {
                                    text: 'OK',
                                    handler: () => {
                                        setValidEmailError(false);
                                    }
                                }
                            ]}
                        />

                        {/* OTP Error */}
                        <IonAlert
                            mode='ios'
                            isOpen={otpError}
                            header={`Invalid Passcode`}
                            message={`Invalid Passcode. Please try again or contact support.`}
                            buttons={[
                                {
                                    text: 'OK',
                                    handler: () => {
                                        setOtpError(false);
                                    }
                                }
                            ]} 
                        />

                    </div>
                </IonContent>
            </IonModal>
        </>
    );
};

export default Login;
