import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../Context';

const ColosseumBackdrop: React.FC = () => {

    const { router } = useContext(UserContext);
    const { collections, theme } = useContext(UserContext);
    const [backdrop, setBackdrop] = React.useState<string | null>(null);

    useEffect(() => {
        if (theme) {
            collections.forEach(collection => {
                if (collection.slug === theme && collection.colosseumBackdrop) {
                    setBackdrop(collection.colosseumBackdrop);
                }
            });
        } else {
            setBackdrop(null);
        }
    }, [theme, collections])

    return (
        <>
        <div className={`colosseum-backdrop ${router?.routeInfo.pathname === '/play/battle' ? 'o-0-' : ''}`}>
            {backdrop ? (
                <>
                {backdrop?.endsWith('.mp4') ? (
                    <video autoPlay loop muted>
                        <source src={backdrop} type="video/mp4" />
                    </video>
                ) : (
                    <img src={backdrop} alt="" />    
                )}
                </>
            ) : null}
        </div>
        <div className="backdrop-overlay" />
        </>
    );
};

export default ColosseumBackdrop;