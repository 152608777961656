// React
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Context';
import { PlayContext, PlayState } from '../../pages/PlayContext';

// Interfaces
import { UserState } from '../../interfaces/User.interface';

// Colyseus
import { TokenMetadata } from '../../generated/TokenMetadata';
import { Ability } from '../../generated/Ability';

// Images
import full from './../../images/energy-assets/energy-slot-full.png';

// Components
import PlayerCardEquipment from './PlayerCardEquipment';
import AbilityIcon from './AbilityIcon';

// Interfaces
import { EquipmentType } from '../../interfaces/Equipment.interface';

// Services

// Styles
import './PlayerCard.scss'

// Utils
import { useSpring, animated } from 'react-spring';
import { getOS } from '../../Utils';
import classNames from 'classnames';
import { AbilityType } from '../../interfaces/Ability.interface';
import { hover } from '../../services/GameServer';

export const cardDefaultDefense = 100;
export const cardDefaultHealth = 100;

interface PlayerCardProps {
  card: TokenMetadata;
  display: 'player' | 'opponent';
  hovered?: boolean;
  showEquipment?: boolean;
}

const PlayerCard: React.FC<PlayerCardProps> = ({
    showEquipment,
    card,
    display,
    hovered,
}) => {

  const { user, setTooltip, setShowTooltip }: UserState = useContext(UserContext);
  const {
    prepareToken,
    frame,
    wearable,
    room,
    abilitySelected,
    myTurn,
    myEnergy,
    myCard,
    setMyCard,
    selectAbility,
    opponentClick,
    selfClick
  }: PlayState = useContext(PlayContext);

  const os = getOS();

  const [owned, setOwned] = useState<boolean>(false);
  const [cardFlipped, setCardFlipped] = useState<boolean>(false);
  const [isDead, setIsDead] = useState<boolean>(false);
  const [hideCard, setHideCard] = useState<boolean>(false);
  const [shrink, setShrink] = useState<boolean>(false);
  const [removed, setRemoved] = useState<boolean>(false);

  // Stats
  const [ prevHealth, setPrevHealth ] = useState<number>(0);
  const [ prevDefense, setPrevDefense ] = useState<number>(0);
  const [ healthDiff, setHealthDiff ] = useState<number | null>(null);
  const [ defenseDiff, setDefenseDiff ] = useState<number | null>(null);
  const [ health, setHealth ] = useState<number | null>(null)
  const [ defense, setDefense ] = useState<number | null>(null)
  const [ ab1, setAb1 ] = useState<Ability>()
  const [ ab2, setAb2 ] = useState<Ability>()

  // If the ability is enabled
  const ab1Enabled = ((ab1 && myEnergy) && (myEnergy >= ab1?.energy)) && myTurn ? true : false;
  const ab2Enabled = ((ab2 && myEnergy) && (myEnergy >= ab2?.energy)) && myTurn ? true : false;

  const { value: healthSpring } = useSpring({
    from: { value: prevHealth },
    to: { value: health },
    config: { duration: 500 },
  });
  
  const { value: defenseSpring } = useSpring({
    from: { value: prevDefense },
    to: { value: defense },
    config: { duration: 500 },
  });

  // Ability Popover
  const [ability, setAbility] = useState<Ability | null>();

  const abilityIcon = (ability: Ability) => {
    return <AbilityIcon ability={ability} />
  }

  // Muffins, token ID, flipped, and selected listener
  useEffect(() => {
    setOwned( display === 'opponent' ? false : true )
    setCardFlipped( card.flipped )
  }, [card.token_id, card.flipped, display, myCard, myTurn, abilitySelected])

  // Stats listener
  useEffect(() => {

    if (card.stats) {

      // This logic will dispay the calculated difference when the card is healed or damaged
      if (health !== null && defense !== null) {

        // Calculate differences
        const newHealthDiff = card.stats.health - health;
        const newDefenseDiff = card.stats.defense - defense;
  
        // Set differences if they are not zero
        setHealthDiff(newHealthDiff);
        setDefenseDiff(newDefenseDiff);
  
        // Reset differences after 1 second
        setTimeout(() => {
          setHealthDiff(null);
          setDefenseDiff(null);
        }, 2 * 1000);
  
        // Health & Defense
        setPrevHealth(health);
        setPrevDefense(defense);

      }

      setHealth(card.stats.health)
      setDefense(card.stats.defense)

      // Abilities
      if (card.stats?.abilities[0] && card.stats?.abilities[1]) {
        setAb1(card.stats.abilities[0]);
        setAb2(card.stats.abilities[1]);
      }

      // Check if dead (health and defense are zero) 💀
      if (card.stats.health <= 0 && card.stats.defense <= 0) {
        setTimeout(() => {
          setIsDead(true);
          // death(card)
          setTimeout(() => {
            setHideCard(true)
            setTimeout(() => {
              setShrink(true)
              setTimeout(() => {
                setRemoved(true)
              }, 250)
            }, 250);
          }, 750)
        }, 750)
      } else {
        setIsDead(false);
      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card, card.stats, card.stats.health, card.stats.defense, display, showEquipment, prepareToken, frame, wearable])

  /**
   * Player Card Image
   * @param image Image URL
   * @returns Image URL
   */
  const image = () => {

    if (!card?.image) {
      return '';
    }

    function convertIPFSUrl(ipfsUrl: string) {
      const ipfsPrefix = 'ipfs://';
      const gatewayUrl = 'https://ipfs.io/ipfs/';
      if (ipfsUrl.startsWith(ipfsPrefix)) {
        return ipfsUrl.replace(ipfsPrefix, gatewayUrl);
      } else {
        return ipfsUrl; // Return the original URL if it doesn't start with 'ipfs://'
      }
    }

    if (card.image.startsWith('ipfs://')) {
      return convertIPFSUrl(card.image)
    } else {
      return card.image.replace('_a.png', '.png')
    }

  }

  /**
   * Player Card Classes
   */
  const cardClasses = classNames(
    'animate__animated',
    'player-card',
    'gladiator-arena',
    'flip-card',
    `owned-${owned}`,
    `my-turn-${myTurn}`,
    `display-${display}`,
    `show-equipment-${showEquipment}`,
    display,
    { hover: hovered },
    { selected: myCard && (myCard.token_id === card.token_id) },
    { 'not-selected': !(myCard && (myCard.token_id === card.token_id)) },
    { flipped: cardFlipped },
    { 'not-flipped': !cardFlipped },
    { 'card-dead': isDead },
    { dissapear: hideCard && isDead },
    { shrinked: hideCard && isDead && shrink },
    { removed: removed },
    `os-${os}`
  );

  return (
    <div 
      id={`card_${card.wallet}_${card.collection}_${card.token_id}`}
      className={cardClasses} 
      onClick={() => { 

          if(!myTurn) {
            return;
          }

          // If this is my card, send card object to set it as flipped
          // if (owned && !flipped) {
            // click()
          // }
  
          // If this is not my card, and it is flipped
          if (!owned && card.flipped) {
            opponentClick(card)
          }

          // If owned and flipped
          if (owned && card.flipped) {
            selfClick(card)
          }

      }}
      onMouseOver={() => {
        if (room) {
          hover(user, room, card)
        }
      }}
      onMouseLeave={() => {
        if (room) {
          hover(user, room, null)
        }
      }}
    >
    <div className={`aspect-ratio aspect-ratio--5x7`}>
        <div className="aspect-ratio--object">
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <div className='front-card-container'>
                      <div className='card-contents animate__animated animate__zoomIn'>
                          <div className="nft" data-tilt data-tilt-glare data-tilt-max-glare="0.4" data-tilt-max="6">
                              <div className="art-container-bg" />
                              <img className="art-container" src={`${ image() }`} loading='lazy' alt='' />
                              <div className="card-text-behind-frame dn">
                                  {ability ? (
                                  <div className={`info`}>
                                      <p className='primary-font mv1 flex justify-start items-center info-ab-name'>
                                          <span className="flex flex-column ml2 tc">
                                              <span>{ability?.name}</span>
                                          </span>
                                      </p>
                                      {ability.type === AbilityType.Attack && (
                                        <p className='mv2 desc power red'>
                                          <span className="inner-label"><span className="value">{ability?.power}</span></span>
                                          <span className="f6 dmg">Damage</span>
                                        </p>
                                      )}
                                      {ability.type === AbilityType.HealDefense && (
                                        <p className='mv2 desc power green'>
                                          <span className="inner-label"><span className="value">+{ability?.power}</span></span>
                                          <span className="f6 dmg">Defense</span>
                                        </p>
                                      )}
                                      <p className='mv2 desc energy'>
                                        <span className="inner-label"><span className="value">{ability.energy}</span> x</span>
                                        <img src={full} className="full" alt='Energy Slot Full' />
                                      </p>
                                  </div>
                                  ) : null}
                              </div>
                              <div className={`card-frame`}>
                                <PlayerCardEquipment card={card} mode={EquipmentType.FRAMES} side='front' />
                              </div>

                              <div className="card-text">
                                {/* {!ability ? ( */}
                                  <>
                                  <div className="name pt-serif">{card.name}</div>
                                  <div className="token show-for-sr">{card?.token_id}</div>
                                  </>
                                {/* ) : null} */}
                                  <div className="stats pt-serif b">

                                    {/* <div className="stat-change-difference defense-calc">
                                      <p className="ma0 mv0 f2 animate__animated animate__bounceIn">+10</p>
                                    </div>
                                    <div className="stat-change-difference health-calc">
                                      <p className="ma0 mv0 f2 animate__animated animate__bounceIn">-50</p>
                                    </div>    */}

                                    {(defenseDiff !== null && defenseDiff !== 0) && (
                                      <div className="stat-change-difference defense-calc">
                                        <p className="ma0 mv0 f2 animate__animated animate__bounceIn">{defenseDiff > 0 ? <span className="green">+{defenseDiff}</span> : <span className="red">{defenseDiff}</span>}</p>
                                      </div>                                    
                                    )}
                                    {(healthDiff !== null && healthDiff !== 0) && (
                                      <div className="stat-change-difference health-calc">
                                        <p className="ma0 mv0 f2 animate__animated animate__bounceIn">{healthDiff > 0 ? <span className="green">+{healthDiff}</span> : <span className="red">{healthDiff}</span>}</p>
                                      </div>                                    
                                    )}
                                    <div className={`health ${health && health <= 0 ? `empty` : ``}`}>
                                      <animated.span className={`health-value mv0 lh-copy`}>
                                        {healthSpring.to((x: number) => Math.floor(x))}
                                      </animated.span>
                                    </div>
                                    <div className={`defense ${defense && defense <= 0 ? `empty` : ``}`}>
                                      <animated.span className={`defense-value mv0 lh-copy`}>
                                        {defenseSpring.to((x: number) => Math.floor(x))}
                                      </animated.span>
                                    </div>
                                    {owned ? (
                                      <>

                                      {/* Ability 1 */}
                                      <button 
                                        disabled={!ab1Enabled}
                                        className={`ab1 self-${ab1?.self} selected-${abilitySelected?.id === ab1?.id}`}
                                        onMouseOver={() => {
                                          if (ab1) { 
                                            setAbility(ab1)
                                            setTooltip({ type: 'ability', data: ab1, collectible: card })
                                            setShowTooltip(true)
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          setShowTooltip(false)
                                          setTooltip({ type: null, data: null, collectible: null })
                                          setAbility(null)
                                        }}
                                        onClick={() => { 
                                          if(!myTurn) {
                                            return;
                                          }
                                          if ((ab1 && myEnergy) && (myEnergy >= ab1?.energy)) { 
                                            setMyCard(card)
                                            selectAbility(ab1, card)
                                          }
                                        }}
                                      >
                                        {ab1 ? abilityIcon(ab1) : null}
                                      </button>

                                      {/* Ability 2 */}
                                      <button 
                                        disabled={!ab2Enabled}
                                        className={`ab2 self-${ab1?.self} selected-${abilitySelected?.id === ab2?.id}`}
                                        onMouseOver={() => {
                                          if (ab2) { 
                                            setAbility(ab2)
                                            setTooltip({ type: 'ability', data: ab2, collectible: card })
                                            setShowTooltip(true)
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          setShowTooltip(false)
                                          setTooltip({ type: null, data: null, collectible: null})
                                          setAbility(null)
                                        }}
                                        onClick={() => {
                                          if(!myTurn) {
                                            return;
                                          }
                                          if ((ab2 && myEnergy) && (myEnergy >= ab2.energy)) { 
                                            setMyCard(card)
                                            selectAbility(ab2, card) 
                                          }
                                        }}
                                      >
                                        {ab2 ? abilityIcon(ab2) : null}
                                      </button>

                                      </>
                                    ) : (
                                      <>
                                      <div className="ab1"> {ab1 ? abilityIcon(ab1) : null}</div>
                                      <div className="ab2"> {ab2 ? abilityIcon(ab2) : null}</div>
                                      </>
                                    )}
                                  </div>
                              </div>

                              <div className={`wearable-image`}>
                                {card.wearable?.id ? (
                                  <PlayerCardEquipment card={card} mode={EquipmentType.WEARABLES} side='front' />
                                ) : null}
                              </div>
                          </div>
                      </div>                      
                    </div>
                </div>
                <div className="flip-card-back">
                    <div className="back-card-container pointer"> 
                      <div className='card-contents animate__animated animate__zoomIn'>
                        <div className="nft" data-tilt data-tilt-glare data-tilt-max-glare="0.4" data-tilt-max="6">
                            <div className={`card-frame`}>
                              <PlayerCardEquipment card={card} mode={EquipmentType.FRAMES} side='back' />
                            </div>
                        </div>
                      </div>
                    </div>
                </div>                  
            </div>
            {isDead ? (
              <div className="fx death" />
            ) : null}
        </div>
    </div>

    </div>  
  );
};

export default PlayerCard;
