// React
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Context';

// Ionic
import { Storage } from '@ionic/storage';
import { IonAvatar, IonBadge, IonContent } from '@ionic/react';

// Styles
import './WalletInfo.scss';

// Interfaces
import { UserState } from '../../interfaces/User.interface';
import { calculateRank } from '../../Utils';
import RankBar from './RankBar';

import heic2any from 'heic2any';
import { readAndCompressImage } from 'browser-image-resizer';
import { supabase, updatePlayerRow } from '../../supabase/api';

// Image
import defaultAvatar from './../../images/gladiator/gladiator-coin.png';

// Prepare local storage
const storage = new Storage();
storage.create();

export interface Playlist {
    name: string;
    src: string;
}

const WalletInfo: React.FC = () => {

    const { user, collections, walletInfo, testnet, setWalletInfo, setAlertHeader, setAlertMessage, setAlertModal, playerStats } = useContext<UserState>(UserContext);

    // Avatar
    const [avatar, setAvatar] = useState<string | null>(null);
    const [avatarFile, setAvatarFile] = useState<File | undefined>(undefined);


    /**
     * Upload an avatar to the storage bucket
     * @param user_id User ID
     * @param file File object
     */
    const uploadAvatar = async (user_id: string, file: File): Promise<any> => {

        // Change file name to user_id and always use .jpg extension
        const fileName = `${user_id}.jpg`;

        // Modify the path to include the user_id as a folder
        const filePath = `${user_id}/${fileName}`;

        // Define the image config
        const config = {
            quality: 0.8,
            maxWidth: 300,
            maxHeight: 300,
            autoRotate: true,
            debug: true
        };

        // If the file is a HEIC image, convert it to JPEG
        if (file.type === 'image/heic') {
            const convertedBlob = await heic2any({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.8
            });

            let finalBlob: Blob;

            if (convertedBlob instanceof Blob) {
                finalBlob = convertedBlob;
            } else if (Array.isArray(convertedBlob)) {
                finalBlob = new Blob(convertedBlob, { type: 'image/jpeg' });
            } else {
                throw new Error('Unexpected output from heic2any');
            }

            file = new File([finalBlob], fileName, { type: 'image/jpeg' });
        }

        // Resize and compress the image
        const resizedImageFile = await readAndCompressImage(file, config);

        // Convert the image to jpg using a canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = URL.createObjectURL(resizedImageFile);
        await new Promise((resolve) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
                resolve(null);
            };
        });
        const jpgImageFile = await new Promise<File>((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    resolve(new File([blob], fileName, { type: 'image/jpeg' }));
                } else {
                    reject('Failed to create blob from canvas');
                }
            }, 'image/jpeg');
        });

        // Delete the existing file
        const { error: deleteError } = await supabase.storage.from('avatars').remove([filePath]);

        if (deleteError) {
            throw deleteError;
        }

        // Upload the new file
        const { data, error: uploadError } = await supabase.storage.from('avatars').upload(filePath, jpgImageFile);

        if (uploadError) {
            throw uploadError;
        } else {
            // Handle success
            console.log('File uploaded successfully:', data);
        }
    }

    /**
     * Handle file change event
     * @param event File change event
     */
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setAvatarFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    /**
     * If a new avatar file is present, upload it to the storage bucket
     * with the user's ID as the file name.
     * If no file is present, dismiss the loading spinner and set the toast message.
     */
    const avatarCheck = useCallback(async () => {
        if (user?.player?.uuid && avatarFile) {
            uploadAvatar(user?.player?.uuid, avatarFile).then(async () => {

                // Use supabase sdk to get the storage url
                const { data } = supabase.storage.from('avatars').getPublicUrl(`${user?.player?.uuid}/${user?.player?.uuid}.jpg`);

                const payload = {
                    ...user.player,
                    avatar: data.publicUrl,
                };

                // Remove the uuid key from the payload
                delete payload.uuid;

                updatePlayerRow(user, payload).then(async (success) => {

                    if (success) {
                        console.log('Profile image updated successfully!');
                        playerStats();

                        // Cleanup variables
                        // setAvatar(null);
                        // setAvatarFile(undefined);
                        // setAlertHeader("Profile Image Updated")
                        // setAlertMessage("Profile image updated successfully!")
                        // setAlertModal(true)
                    } else {
                        console.log('Error updating profile image! Please try again or contact support.');
                        // setAlertHeader("Profile Image Error")
                        // setAlertMessage("Error updating profile image! Please try again or contact support.")
                        // setAlertModal(true)
                    }
                });

                // setAlertHeader("Profile Image Updated")
                // setAlertMessage("Profile image updated successfully!")
                // setAlertModal(true)
            }).catch(error => {
                setAlertHeader("Profile Image Error")
                setAlertMessage("Error uploading profile image! Please try again or contact support.")
                setAlertModal(true)
            });
        }
    }, [avatarFile, user?.player?.uuid, setAlertHeader, setAlertMessage, setAlertModal]);

    useEffect(() => {
        avatarCheck();
    }, [avatarFile , avatarCheck]);

    useEffect(() => {

        /**
         If isOpen is true, that means the settings panel is open
         if the user clicks outside of the #panel-settings setIsOpen to false
         */ 
        document.addEventListener('click', (e: any) => {
            const panelWallet = document.getElementById('wallet-settings');
            const panelWalletButton = document.getElementById('wallet-button');
            // If the target is the panel settings button, do nothing
            if (panelWalletButton && panelWalletButton.contains(e.target)) {
                return;
            }
            if (walletInfo) {
                if (panelWallet && !panelWallet.contains(e.target)) {
                    setWalletInfo(false);
                }
            }
        });
        
    }, [walletInfo, setWalletInfo])

    return (
        <>
        <div id='wallet-settings' className={`panel-gladiator top-right ${walletInfo ? `open` : ``}`}>
            <IonContent className="ion-padding">

                <div className="row mt0 pt2 pb2 mb3 dn">
                    <div className="columns small-12 flex justify-start items-center ph0">
                        <div className='avatar-upload small tc dn'>
                            <label htmlFor="avatar-upload">
                                <IonAvatar>
                                    <img src={`${avatar ? avatar : user.player?.avatar ? user.player?.avatar : defaultAvatar}`} alt="" />
                                </IonAvatar>
                            </label>
                            <input id="avatar-upload" type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />
                        </div>
                        <p className='pt-serif f4 bb b--white-20 w-100 pb2 mb1 mt2 ttc fw6'>
                            {user.player?.display_name ? user.player.display_name : 'User'}
                        </p>
                    </div>
                </div>

                <div className="row ba b--white-20 mt0 pt2 pb2">
                    <div className="columns small-12 flex justify-between items-center">
                        <p className='pt-serif f4 bb b--white-20 w-100 pb2 mb3 mt2 ttc fw6 tr db w-100 flex justify-between'><span className="o-50 fw5">Rank:</span> {calculateRank(user)}</p>
                    </div>
                    <div className="columns small-12">
                        <RankBar xp={user.player?.xp ? user.player.xp : 0} />
                    </div>
                    <div className="columns small-12 flex justify-around funds-detail">
                        <p className='mv0'>
                            <span className="currency-amount">
                                <IonBadge>
                                    <span className="number f4 pt-serif fw7">{user.player?.xp ? user.player.xp.toLocaleString() : 0}</span>
                                </IonBadge>
                            </span>
                            <span className="currency-label pt-serif fw7 ttc">
                                XP
                            </span>
                        </p>
                        <p className='mv0'>
                            <span className="currency-amount">
                                <IonBadge>
                                    <span className="number f4 pt-serif fw7">{user.player?.wins ? user.player.wins.toLocaleString() : 0}</span>
                                </IonBadge>
                            </span>
                            <span className="currency-label pt-serif fw7 ttc">
                                Wins
                            </span>
                        </p>
                        <p className='mv0'>
                            <span className="currency-amount">
                                <IonBadge>
                                    <span className="number f4 pt-serif fw7">{user.player?.losses ? user.player.losses.toLocaleString() : 0}</span>
                                </IonBadge>
                            </span>
                            <span className="currency-label pt-serif fw7 ttc">
                                Losses
                            </span>
                        </p>
                        <p className='mv0'>
                            <span className="currency-amount">
                                <IonBadge>
                                    <span className="number f4 pt-serif fw7">{user.player?.ties ? user.player.ties : 0}</span>
                                </IonBadge>
                            </span>
                            <span className="currency-label pt-serif fw7 ttc">
                                Ties
                            </span>
                        </p>
                    </div>                                
                </div>
                <div className="row ba b--white-20 mt3 pt2 pb2">
                    <div className="columns small-12 flex justify-start items-center">
                        <p className={`pt-serif f4 w-100 mt2 ttc fw6 flex items-center ${user.emailUser ? `justify-between mb1` : `justify-start pb2 mb3 bb b--white-20`}`}>
                            <span>Funds</span>
                            {user.emailUser && (
                                <span className='mv0 flex flex-row-reverse items-center'>
                                    <span className="currency-amount">
                                        <IonBadge>
                                            <span className="number f4 pt-serif fw7">{user.gladiiBalance.toLocaleString()}</span>
                                        </IonBadge>
                                    </span>
                                    <span className="currency-label pt-serif fw7 ttc mr2 mb1 f6">
                                        GLADII
                                    </span>
                                </span>
                            )}
                        </p>
                    </div>
                    {!user.emailUser && (
                        <div className="columns small-12 flex justify-around funds-detail">
                            <p className='mv0'>
                                <span className="currency-amount">
                                    <IonBadge>
                                        <span className="number f4 pt-serif fw7">{user.ethBalance?.toFixed(4)}</span>
                                    </IonBadge>
                                </span>
                                <span className="currency-label pt-serif fw7 ttc">
                                    ETH
                                </span>
                            </p>
                            <p className='mv0'>
                                <span className="currency-amount">
                                    <IonBadge>
                                        <span className="number f4 pt-serif fw7">{user.erc20Balance.toFixed(4)}</span>
                                    </IonBadge>
                                </span>
                                <span className="currency-label pt-serif fw7 ttc">
                                    {testnet ? 'LINK' : 'USDC'}
                                </span>
                            </p>
                            <p className='mv0'>
                                <span className="currency-amount">
                                    <IonBadge>
                                        <span className="number f4 pt-serif fw7">{user.gladiiBalance.toLocaleString()}</span>
                                    </IonBadge>
                                </span>
                                <span className="currency-label pt-serif fw7 ttc">
                                    GLADII
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                <div className="row ba b--white-20 mt3 pt2 pb2 mb5">
                    <div className="columns small-12 flex justify-start items-center">
                        <p className='pt-serif f4 bb b--white-20 w-100 pb2 mb3 mt2 ttc fw6'>Collection</p>
                    </div>
                    <div className="columns small-12">
                        {collections.map((collection, index) => {
                            return (
                                <div key={index} className="flex items-center w-100">
                                    {/* <div className='pa2'>
                                        <IonAvatar>
                                            <img src={collection.logo} alt="" />
                                        </IonAvatar>
                                    </div> */}
                                    <p className='pt-serif fw7 mt0 mb2 pv0 w-100 flex justify-between items-center'>
                                        <span>{collection.name}:</span>
                                        <span className='count'>
                                            <IonBadge>
                                                <span className="number f4 pt-serif fw7">{user.collectibles?.filter(c => c.collection === collection.slug)?.length}</span>
                                            </IonBadge>
                                        </span>
                                    </p>
                                </div>
                            )
                        })}
                    </div>                                
                </div>
            </IonContent>
        </div>
        </>
    );
};

export default WalletInfo;
